import React, { useContext, useEffect, useState } from "react";
import {
  StyleSheet,
  Pressable,
  Text,
  View,
} from "react-native";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import CustomTextInput from "../CustomElements/CustomTextInput"
import { UUID } from "../../utils/UUID";
import { Storage } from 'aws-amplify';
import { AvatarImage } from "./AvatarImage";
import { MyRig } from "./MyRig";
import { LoadingComponent } from "../LoadingComponent";
import { UserDataContext } from "../../utils/UserContextProvider";
import CustomDivider from "../CustomDivider";
import { useSelector } from "react-redux";
import { colors } from "../../styles/colors";
import CustomButton from "../CustomElements/CustomButton";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import getEnvVars from "../../../environment";
import { updateUserMutation } from "../../api/user";
import { createRigMutation, updateRigMutation } from "../../api/rig";
const { cloudfrontURL } = getEnvVars();

const ShowDetailsAccount = ({ userState, screenWidth, image }) => {
  const { userData } = userState;
  return (
    <>
      <Flex
        textAlign="left"
        direction="column"
      >
        <Text style={{ fontWeight: "bold", marginRight: 20, }}>About</Text>
        <Text style={{ width: screenWidth / 1.5, marginTop: 10, marginLeft: 15 }}>{userData && userData.about || "nothing here yet!"}</Text>
        <Flex direction={screenWidth > 400 ? 'row' : 'column'}>
          <AvatarImage
            image={image}
          />
        </Flex>
      </Flex>
    </>
  );
};

const EditDetailsAccount = ({
  setEditProfile,
  editProfile,
  aboutValue,
  setAboutValue,
  handleSubmit,
  uploadResourceS3,
  loading,
  image,
  width,
  setImage,
  mobileView
}) => {
  if (loading) {
    return (<LoadingComponent />)
  };
  return (
    <Flex as="form" direction="column" marginBottom={20}>
      <CustomTextInput
        label="About"
        onChangeText={(e) => setAboutValue(e)}
        value={aboutValue}
        placeholder="A little about me..."
        showTextCount={true}
        multiline={true}
        height={160}
        width={mobileView ? width / 1 : width / 1.5}
      />
      <AvatarImage
        editProfile={editProfile}
        image={image}
        uploadResourceS3={(v) => uploadResourceS3(v)}
        setImage={(v) => setImage(v)}
      />
      <View style={styles.buttonSection}>
        <Pressable onPress={() => setEditProfile()} disabled={loading} style={styles.backButton}>
          <Text style={styles.backButtonText}>Cancel</Text>
        </Pressable>
        <Pressable
          onPress={() => handleSubmit()}
          style={styles.forwardButton}
          disabled={loading}
        >
          <Text style={styles.forwardButtonText}>Save</Text>
        </Pressable>
      </View>
    </Flex>
  );
};

export const DetailsAccount = ({ screenWidth }) => {
  const { mobileView } = useSelector(state => state.currentUser);
  const { user } = useContext(UserDataContext);
  const { userData, setUserData } = user;
  const { avatarImage } = userData;
  const myRig = userData?.userData?.rig?.items?.length && userData.userData.rig.items[0]
  const updateUserData = (v) => setUserData(v);
  const [editProfile, setEditProfile] = useState(false);
  const [error, setError] = useState(false);
  const [aboutValue, setAboutValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [newRigImage, setNewRigImage] = useState(null);
  const [rigImage, setRigImage] = useState(null);
  const [rigError, setRigError] = useState(false);
  const [rigLoading, setRigLoading] = useState(false);
  const [rigYear, setRigYear] = useState(myRig?.year || null);
  const [make, setmake] = useState(myRig?.make || '');
  const [model, setmodel] = useState(myRig?.model || '');
  const [rigLength, setrigLength] = useState(myRig?.length || '');
  const [rigWidth, setrigWidth] = useState(myRig?.width || '');
  const [rigData, setRigData] = useState(myRig || '');
  const [editRigData, setEditRigData] = useState(false);

  const updateUser = async (img = null) => {
    const saveImg = img ? img : userData.userData.avatar;
    const cloudfrontImg = saveImg ? `${cloudfrontURL}${saveImg}` : null;
    setLoading(true);
    let data = {
      id: userData.sub,
      about: aboutValue,
      avatar: saveImg,
      _version: 1
    }
    let updatedUser = await updateUserMutation({ currentUserData: {...userData, avatarImage: cloudfrontImg}, data })
    if (updatedUser) {
      updateUserData(updatedUser)
      setLoading(false);
      setEditProfile(false);
    } else {
      setLoading(false);
      setEditProfile(false);
      setError(true)
    }
  };

  const updateRig = async () => {
    setRigLoading(true)
    const saveImg = newRigImage || rigImage;
    const updateExistingRig = !!rigData?.id;
    const myNestedData = userData.userData;
    const rigID = updateExistingRig ? rigData.id : UUID();
    const data = {
      id: rigID,
      ownerID: userData?.sub,
      year: rigYear,
      image: saveImg,
    };
    if (make) { data.make = make }
    if (model) { data.model = model }
    if (rigLength) { data.length = rigLength }
    if (rigWidth) { data.width = rigWidth }
    myNestedData.rig.items[0] = data;

    let updatedRig = updateExistingRig ? await updateRigMutation({ data }) : await createRigMutation({ data });
    if (updatedRig) {
      setRigLoading(false);
      updateUserData({
        ...userData,
        myRigImage: saveImg,
        userData: myNestedData
      })
    } else {
      setRigLoading(false);
      setRigError(true)
    }
    setEditRigData(false);
  };

  const uploadAvatarResourceS3 = async () => {
    setLoading(true)
    const newProfileImg = `${UUID()}.jpg`;
    const response = await fetch(image);
    const blob = await response.blob();

    await Storage.put(newProfileImg, blob, {
      level: "public",
      contentType: "image/jpg"
    })
      .then((x) => {
        updateUser(newProfileImg);
      })
      .catch((r) => {
        console.log(" Error uploading IMAGE ", r)
        setLoading(false)
        setError(r)
      })
  };

  const uploadRigResourceS3 = async () => {
    setRigLoading(true)
    const rigImageID = `${UUID()}.jpg`;
    const response = await fetch(rigImage);
    const blob = await response.blob();

    await Storage.put(rigImageID, blob, {
      level: "public",
      contentType: "image/jpg"
    })
      .then((x) => {
        setNewRigImage(rigImageID)
        setRigLoading(false)
      })
      .catch((r) => {
        console.log(" Error uploading IMAGE ", r)
        setRigLoading(false)
        setRigError(r)
      })
  };

  useEffect(() => {
    avatarImage && setImage(avatarImage)
    userData?.userData?.about && setAboutValue(userData?.userData?.about)
    myRig?.image && setRigImage(`${cloudfrontURL}${myRig.image}`)
  }, [userData]);

  return (
    <View>
      <Header padding={0} level={1.5} marginBottom={20} marginTop={25} text={`Hello, ${userData.userName}`} />
      {editProfile ? (
        <EditDetailsAccount
          userState={userData}
          mobileView={mobileView}
          image={image}
          setImage={(v) => setImage(v)}
          editProfile={editProfile}
          setEditProfile={() => setEditProfile(false)}
          setError={(v) => setError(v)}
          aboutValue={aboutValue}
          width={screenWidth}
          setAboutValue={(v) => setAboutValue(v)}
          loading={loading}
          handleSubmit={(e) => updateUser(e)}
          uploadResourceS3={() => uploadAvatarResourceS3()}
        />
      ) : (
        <>
          <ShowDetailsAccount
            userState={userData}
            editProfile={editProfile}
            screenWidth={screenWidth}
            image={image}
          />
          {!!error && (<ErrorDisplay error="Error updating Profile" />)}
          <View>
            <CustomButton
              width={150}
              marginTop={20}
              marginBottom={20}
              backgroundColor={colors.orange}
              onPress={() => setEditProfile(true)}
              title="Edit profile"
            ><Text style={{ fontWeight: "bold", color: "#fff" }}>EDIT PROFILE</Text>
            </CustomButton>
          </View>
        </>
      )}
      <CustomDivider />
      <MyRig
        userState={userData}
        mobileView={mobileView}
        image={rigImage}
        loading={rigLoading}
        editRigData={editRigData}
        setEditRigData={(v) => setEditRigData(v)}
        setImage={(v) => setRigImage(v)}
        editProfile={editProfile}
        setError={(v) => setRigError(v)}
        error={rigError}
        width={screenWidth}
        handleSubmit={() => updateRig()}
        uploadResourceS3={() => uploadRigResourceS3()}
        rigYear={rigYear}
        setRigYear={(v) => setRigYear(v)}
        make={make}
        setmake={(v) => setmake(v)}
        model={model}
        setmodel={(v) => setmodel(v)}
        rigLength={rigLength}
        setrigLength={(v) => setrigLength(v)}
        rigWidth={rigWidth}
        setrigWidth={(v) => setrigWidth(v)}
        rigData={rigData}
        setrigData={(v) => setRigData(v)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  aboutArea: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.amplifyNeutral80,
    padding: 10
  },
  cancelbutton: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 1,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "white",
  },
  canceltext: {
    fontSize: 12,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "black",
  },
  buttonSection: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 4,
  },
  backButtonText: {
    color: "#484a4a",
    fontSize: 20,
    fontWeight: "bold",
  },
  backButton: {
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
  },
  forwardButtonText: {
    color: "#fff",
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#065d8c",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    marginLeft: 40,
  },
});
