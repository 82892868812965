import React, { useEffect } from "react";
import { useWindowDimensions, Text, Pressable } from "react-native";
import Flex from "../CustomElements/Flex"
import Header from "../CustomElements/Header"
import Card from "../CustomElements/Card"
import { TimePicker } from "./TimePicker";
import { RefundPicker } from "./RefundPicker";
import { MaterialIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import CustomTextInput from "../CustomElements/CustomTextInput";
import { colors } from "../../styles/colors";
import { Switch } from "react-native-gesture-handler";
import InputCard from "../CustomElements/InputCard";

const SetThingsToKnow = ({
    fullRefundDate,
    halfRefundDate,
    checkInTime,
    checkOutTime,
    quietStart,
    quietEnd,
    spaceRules,
    checkInInstructions,
    setcheckInInstructions,
    instantBook,
    setInstantBook,
    setquietEnd,
    setfullRefundDate,
    sethalfRefundDate,
    setcheckInTime,
    setcheckOutTime,
    setspaceRules,
    setquietStart,
    setDisabled,
    checkInDefined,
    setcheckInDefined,
    quietTimeDefined,
    setquietTimeDefined,
    wifiNetwork,
    wifiPassword,
    setwifiNetwork,
    setwifiPassword,
    wifiAvailable
}) => {
    const { mobileView } = useSelector(state => state.currentUser);
    const { width } = useWindowDimensions();

    useEffect(() => {
        const wifiRequired = wifiAvailable ? !!(wifiNetwork && wifiPassword) : true
        !!spaceRules.length && wifiRequired ? setDisabled(false) : setDisabled(true)
    }, [spaceRules, wifiNetwork, wifiPassword])

    return (
        <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            width={width / 1.2}
            marginBottom={20}
        >
            <InputCard
                headerText={"Rules and Policies"}
                required={true}
            >
                <CustomTextInput
                    height={300}
                    maxLength={false}
                    multiline={true}
                    width={mobileView ? width / 1.2 : width / 1.4}
                    placeholder="ex: RV insurance policy required. No clutter or items left outside trailer. The Host is not to be held liable for any injury that happens on the property..."
                    errorMessage="There is an error"
                    value={spaceRules}
                    marginBottom={1}
                    onChangeText={(value) => setspaceRules(value)}
                />
            </InputCard>
            {wifiAvailable && (
                <InputCard
                    headerText={"Wifi Details"}
                    required={true}
                    subtext="This will not be shared until a renter is booked."
                >
                    <CustomTextInput
                        height={40}
                        maxLength={0}
                        width={mobileView ? width / 1.4 : width / 1.8}
                        placeholder="network name"
                        errorMessage="There is an error"
                        value={wifiNetwork}
                        marginBottom={4}
                        onChangeText={(value) => setwifiNetwork(value)}
                    />
                    <CustomTextInput
                        height={40}
                        maxLength={0}
                        width={mobileView ? width / 1.4 : width / 1.8}
                        placeholder="network password"
                        errorMessage="There is an error"
                        value={wifiPassword}
                        marginBottom={2}
                        onChangeText={(value) => setwifiPassword(value)}
                    />
                </InputCard>
            )}

            <InputCard
                headerText={"Allow Renters to Instant Book"}
                required={false}
            >
                <Switch
                    trackColor={{ false: '#767577', true: '#81b0ff' }}
                    thumbColor={instantBook ? '#f4f3f4' : '#f4f3f4'}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={() => setInstantBook()}
                    value={instantBook}
                    style={{ transform: [{ scaleX: 1.2 }, { scaleY: 1.2 }] }}
                />
                <Text style={{ fontSize: 15, width: width / 1.3, textAlign: "center", marginTop: 25 }}>
                    <Text>With Instant Book, a renter </Text>
                    <Text style={{ fontWeight: "bold" }}>can book the space immediately </Text>
                    without waiting for your review or approval.
                </Text>
            </InputCard>

            <InputCard
                headerText={"Cancellation Policy"}
                required={false}
            >
                <Text style={{ fontSize: 15, width: width / 1.4, textAlign: "center", marginTop: 10 }}>
                    <Text> Number of </Text>
                    <Text style={{ fontWeight: "bold" }}>weeks </Text>
                    prior to your reservation start dates a renter can get a
                    <Text style={{ fontWeight: "bold" }}> refund.</Text>
                </Text>
                <RefundPicker
                    type="100% refund"
                    time={fullRefundDate}
                    updateTime={(v) => setfullRefundDate(v)}
                />
                <RefundPicker
                    type="50% refund"
                    time={halfRefundDate}
                    updateTime={(v) => sethalfRefundDate(v)}
                />
            </InputCard>

            <InputCard
                headerText={"Check In and Check Out"}
                required={false}
            >
                <Pressable onPress={() => setcheckInDefined(true)}>
                    <Card
                        backgroundColor={checkInDefined ? "#fff" : colors.amplifyNeutral60}
                        marginTop={25}
                        padding={10}
                        width={!!mobileView ? width / 1.3 : width / 1.5}
                    >
                        <Flex width={mobileView ? width / 1.35 : width / 1.6} direction="column" justifyContent="center" alignContent="center" alignItems="center" >
                            <Pressable onPress={() => setcheckInDefined(!checkInDefined)}>
                                <Flex width={mobileView ? width / 1.35 : width / 1.6} justifyContent="center" alignContent="center" alignItems="center" marginBottom={0}>
                                    {!checkInDefined ? (
                                        <MaterialIcons name="check-box-outline-blank" size={34} color="#454545" />
                                    ) : (
                                        <MaterialIcons name="check-box" size={34} color="#5c5c5c" />
                                    )}
                                    <Text style={{ fontSize: 17, textAlign: "center" }}>Set Check-in and Check-out times</Text>
                                </Flex>
                            </Pressable>
                            {checkInDefined && (
                                <Flex width={mobileView ? width / 1.35 : width / 1.6} direction="column" justifyContent="center" alignContent="center" alignItems="center" >
                                    <TimePicker mobileView={mobileView} type="Check-In" subText="AFTER:" time={checkInTime} updateTime={(v) => setcheckInTime(v)} enabled={checkInDefined} />
                                    <TimePicker mobileView={mobileView} type="Check-Out" subText="BEFORE:" time={checkOutTime} updateTime={(v) => setcheckOutTime(v)} enabled={checkInDefined} />
                                </Flex>
                            )}
                        </Flex>
                    </Card>
                </Pressable>
                <Pressable onPress={() => setquietTimeDefined(true)}>
                    <Card
                        backgroundColor={quietTimeDefined ? "#fff" : colors.amplifyNeutral60}
                        marginTop={6}
                        padding={!!mobileView ? 4 : 9}
                        width={!!mobileView ? width / 1.3 : width / 1.5}
                    >
                        <Pressable onPress={() => setquietTimeDefined(!quietTimeDefined)}>
                            <Flex width={mobileView ? width / 1.35 : width / 1.6} justifyContent="center" alignContent="center" alignItems="center" marginBottom={0}>
                                {!quietTimeDefined ? (
                                    <MaterialIcons name="check-box-outline-blank" size={34} color="#454545" />
                                ) : (
                                    <MaterialIcons name="check-box" size={34} color="#5c5c5c" />
                                )}
                                <Text style={{ fontSize: 17, textAlign: "center" }}>Set Quiet hours</Text>
                            </Flex>
                        </Pressable>
                        {quietTimeDefined && (
                            <Flex width={mobileView ? width / 1.35 : width / 1.6} direction="column" justifyContent="center" alignContent="center" alignItems="center" >
                                <TimePicker mobileView={mobileView} type="Quiet hours begin:" time={quietStart} updateTime={(v) => setquietStart(v)} enabled={quietTimeDefined} />
                                <TimePicker mobileView={mobileView} type="Quiet hours end:" time={quietEnd} updateTime={(v) => setquietEnd(v)} enabled={quietTimeDefined} />
                            </Flex>
                        )}
                    </Card>
                </Pressable>
                <Header textAlign={"left"} marginTop={25} level={.9} color={colors.amplifyNeutral80} text="Check-In Instructions:" />
                <CustomTextInput
                    height={300}
                    maxLength={0}
                    multiline={true}
                    width={mobileView ? width / 1.2 : width / 1.4}
                    placeholder="ex: When you arrive at address, turn right after entering gate. The spots will be labelled accordingly- if you have any issues feel free to send me a message in the app."
                    errorMessage="There is an error"
                    value={checkInInstructions}
                    marginBottom={1}
                    onChangeText={(value) => setcheckInInstructions(value)}
                />
            </InputCard>
        </Flex>
    );
};

export default SetThingsToKnow;


