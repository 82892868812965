import React, { useContext } from "react";
import Property from "./Property";
import Flex from "../CustomElements/Flex"
import { UserDataContext } from "../../utils/UserContextProvider";


function PropertiesGrid({ properties, showCardBackground = false, mobileView }) {
  const data = useContext(UserDataContext);

  return (
    <Flex
      alignContent="center"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      direction="row"
      wrap="wrap"
      marginTop={mobileView ? 0 : 10}
      marginRight={mobileView ? 0 : 10}
      marginLeft={mobileView ? 0 : 10}
      marginBottom={50}
    >
      {properties.map((v, i) => <Property showCardBackground={showCardBackground} property={v} key={i} userData={data?.user?.userData} />)}
    </Flex>
  );
}

export default PropertiesGrid;
