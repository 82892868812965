import React, { useState, useEffect } from "react";
import { StyleSheet, useWindowDimensions, ScrollView, Text, Image, View, Pressable, ImageBackground } from "react-native";
import Header from "../../components/CustomElements/Header"
import LearnMoreHostSplash from "../../components/LearnMoreHostSplash"
import { useDispatch, useSelector } from "react-redux";
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import CustomButton from "../../components/CustomElements/CustomButton";
import { colors } from "../../styles/colors";
import CustomSection from "../../components/CustomElements/CustomSection";
import HelpForm from "../../components/other/HelpForm";
import { Auth } from "aws-amplify";
import getEnvVars from "../../../environment";
import { globalStyles } from "../../styles/styles";
import Badge from "../../components/CustomElements/Badge";
import CustomSlider from "../../components/other/CustomSlider";
import TextBody from "../../components/CustomElements/TextBody";
import SelectAmenities from "../../components/space/SelectAmenities";
import CustomDivider from "../../components/CustomDivider";
import FooterView from "../../components/other/FooterView";
import ComparisonToCompetitorsChart from "../../components/other/ComparisonToCompetitorsChart";
import { SignUpModal } from "../../components/user/SignUpModal";
import ReviewDisplay from "../../components/other/ReviewDisplay";
import { setAuthed } from "../../redux/actions/CurrentUserActions";


const HostSpaceButton = ({ authed, navigate, setSignUpModalOpen, mobileView }) => {
    return (
        <CustomButton
            width={200}
            backgroundColor={colors.orange}
            color="#fff"
            textAlign="center"
            marginLeft={20}
            height={mobileView ? 35 : 45}
            border={colors.amplifyNeutral90}
            onPress={() => authed ? navigate() : setSignUpModalOpen(true)}
        >
            <View style={globalStyles.center}>
                <AntDesign name="pluscircleo" size={24} color="white" />
                <Text style={{ marginLeft: 10, color: "white", fontWeight: "500" }}>Host my Space</Text>
            </View>
        </CustomButton>
    )
}
function HostSplashScreen() {
    const { cloudfrontURLStaticImages } = getEnvVars();
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);
    const [authenticated, setAuthenticated] = useState(false)
    const [stayType, setStayType] = useState('monthly');
    const [water, setWater] = useState(true)
    const [electric, setElectric] = useState(true)
    const [sewage, setSewage] = useState(true)
    const [deluxe, setDeluxe] = useState(false)
    const [nightlyCost, setNightlyCost] = useState(40)
    const [numNights, setNumNights] = useState(30)
    const [showExplanation, setShowExplanation] = useState(false)
    const [signUpModalOpen, setSignUpModalOpen] = useState(false)

    const authCheck = async () => {
        await Auth.currentUserInfo()
            .then((userInfo) => {
                if (userInfo && userInfo.attributes) {
                    setAuthenticated(true)
                } else { setAuthenticated(false) }
            })
    }

    useEffect(() => {
        authCheck();
    }, [])

    const authenticate = () => {
        dispatch(setAuthed({ setAuthed: true }))
    }

    useEffect(() => {
        const optionsSelected = stayType + (electric ? "_electric" : "") + (water ? "_water" : "") + (sewage ? "_sewage" : "") + (deluxe ? "_deluxe" : "")
        const price = {
            vacation: "20",
            vacation_water: "30",
            vacation_sewage: "30",
            vacation_electric: "30",
            vacation_electric_water: "40",
            vacation_water_sewage: "35",
            vacation_electric_sewage: "35",
            vacation_electric_water_sewage: "70",
            vacation_electric_water_sewage_deluxe: "200",

            monthly: "12",
            monthly_water: "15",
            monthly_electric: "15",
            monthly_sewage: "15",
            monthly_electric_water: "20",
            monthly_water_sewage: "20",
            monthly_electric_sewage: "20",
            monthly_electric_water_sewage: "45",
            monthly_electric_water_sewage_deluxe: "60",

            type_yearly: "10",
            type_yearly_water: "15",
            type_yearly_electric: "15",
            type_yearly_sewage: "15",
            type_yearly_electric_water: "18",
            type_yearly_water_sewage: "18",
            type_yearly_electric_sewage: "18",
            type_yearly_water_sewage: "18",
            type_yearly_electric_water_sewage: "20",
            type_yearly_electric_water_sewage_deluxe: "45",

            storage: "2",
            storage_water: "3",
            storage_electric: "3",
            storage_sewage: "3",
            storage_electric_water: "5",
            storage_water_sewage: "5",
            storage_electric_sewage: "5",
            storage_water_sewage: "5",
            storage_electric_water_sewage: "8",
            storage_electric_water_sewage_deluxe: "10",
        }
        setNightlyCost(price[optionsSelected]);
    }, [water, electric, sewage, deluxe, stayType]);


    return (
        <ScrollView>
            <View style={[globalStyles.flexEnd, { backgroundColor: "#fff" }]}>
                <View style={{ margin: 15 }}>
                    <HostSpaceButton authed={authenticated} navigate={() => navigation.navigate("HostCreateSpaceNavigator")} setSignUpModalOpen={() => setSignUpModalOpen(true)} mobileView={mobileView} />
                </View>
            </View>
            <View style={[globalStyles.centerColumn, { backgroundColor: "#fff" }]}>
                <Header fontWeight="400" marginTop={70} padding={0} textAlign="center" color={colors.hookhubOrangeDark} level={!!mobileView ? 2.1 : 4} text={`You could earn`} />
                <Header padding={0} marginBottom={20} textAlign="center" level={!!mobileView ? .8 : 1.3} color={colors.amplifyNeutral80} text={`With your Unused Land Space`} />
                <Header textAlign="center" color={colors.amplifyNeutral90} level={!!mobileView ? 3.5 : 5} text={`$ ${Number(numNights * nightlyCost).toLocaleString("en-US", { minimumFractionDigits: 0 })}`} />

                {/* Price Calculator */}
                <View style={[globalStyles.centerColumn, { marginTop: 60, width: width / 1.1 }]}>
                    <TextBody style={{ marginTop: mobileView ? 20 : 50, fontSize: 17, textAlign: "center" }}><TextBody style={{ fontWeight: "bold", fontSize: 20 }}>{`${numNights}`}</TextBody> {`night${numNights > 1 ? "s" : ""} at estimated `}<TextBody style={{ fontWeight: "bold", fontSize: 20 }}>$ {nightlyCost} </TextBody>{`a night`}</TextBody>
                    <CustomSlider
                        width={mobileView ? width / 1.3 : width / 2}
                        value={numNights}
                        setValue={(v) => setNumNights(v)}
                    />
                    <View style={[globalStyles.centerColumn, { marginBottom: 50, marginTop: 20 }]}>
                        <View style={[globalStyles.center, { flexWrap: "wrap" }]}>
                            <Pressable style={{ width: mobileView ? 80 : 90, height: 35 }} onPress={() => setStayType('vacation')}>
                                <Badge onPress={() => setStayType('vacation')} margin={0} height={25} padding={mobileView ? 1 : 5} variation={stayType == 'vacation' ? "warning" : "info"}>
                                    <Text style={styles.fontSize}>Nightly</Text>
                                </Badge>
                            </Pressable>
                            <Pressable style={{ width: mobileView ? 80 : 90, height: 35 }} onPress={() => setStayType('monthly')}>
                                <Badge onPress={() => setStayType('monthly')} margin={0} height={25} padding={mobileView ? 1 : 5} variation={stayType == 'monthly' ? "warning" : "info"}>
                                    <Text style={styles.fontSize}>Monthly</Text>
                                </Badge>
                            </Pressable>
                            <Pressable style={{ width: mobileView ? 80 : 90, height: 35 }} onPress={() => setStayType('type_yearly')}>
                                <Badge onPress={() => setStayType('type_yearly')} margin={0} height={25} padding={mobileView ? 1 : 5} variation={stayType == 'type_yearly' ? "warning" : "info"}>
                                    <Text style={styles.fontSize}>Yearly</Text>
                                </Badge>
                            </Pressable>
                            <Pressable style={{ width: mobileView ? 80 : 90, height: 35 }} onPress={() => setStayType('storage')}>
                                <Badge onPress={() => setStayType('storage')} margin={0} height={25} padding={mobileView ? 1 : 5} variation={stayType == 'storage' ? "warning" : "info"}>
                                    <Text style={styles.fontSize}>Storage</Text>
                                </Badge>
                            </Pressable>
                        </View>
                        <SelectAmenities
                            width={width}
                            water={water}
                            setWater={(v) => {
                                if (!!deluxe && water) { setDeluxe(false) }
                                setWater(!water)
                            }}
                            electric={electric}
                            setElectric={(v) => {
                                if (!!deluxe && electric) { setDeluxe(false) }
                                setElectric(!electric)
                            }}
                            sewage={sewage}
                            setSewage={(v) => {
                                if (!!deluxe && sewage) { setDeluxe(false) }
                                setSewage(!sewage)
                            }}
                            deluxe={deluxe}
                            setDeluxe={(v) => {
                                if (!deluxe) {
                                    setSewage(true)
                                    setElectric(true)
                                    setWater(true)
                                }
                                setDeluxe(!deluxe)
                            }}
                        />
                        <View style={{ marginVertical: 20 }}>
                            {showExplanation ? (
                                <View style={[globalStyles.centerColumn, { width: width / 1.1 }]}>
                                    <TextBody style={{ fontWeight: "bold", marginBottom: 15 }}>Please note, the above amenity options are not everything you will be able to add to your space. This is a general price calculator. We recommend you still check prices in your local area as you may be able to get more for your property. </TextBody>
                                    <TextBody>Amenities and hook-ups are optional, you can charge more with amenities.</TextBody>
                                    <TextBody>These rates are based on Arizona. We will soon have the option to check your local area's current rates.</TextBody>
                                    <CustomDivider marginVertical={50} width={mobileView ? width / 1.5 : width / 2.5} />
                                    <TextBody style={{ marginBottom: 10 }}><TextBody style={{ fontWeight: "bold" }}>NIGHTLY rate:</TextBody> Basic RV campsites (with basic amenities including water, electric and sometimes sewage) typically cost between $30–$70 per night, while more luxurious ones can cost $80–$200 per night.</TextBody>
                                    <TextBody style={{ marginBottom: 10 }}><TextBody style={{ fontWeight: "bold" }}>LONG TERM rate:</TextBody> Basic RV campsites (with basic amenities including water, electric and sewage) typically cost between $13–$30 per night, while more luxurious ones can cost $35–$55 per night.</TextBody>
                                    <TextBody style={{ marginBottom: 10 }}><TextBody style={{ fontWeight: "bold" }}>* Deluxe:</TextBody> This option refers to a more luxurious space, including extra features and amenities that aren't typical. These can include but are not limited to amenities such as: pool, hottub, gated community, cover for RV/trailer, or bathroom/shower facilities.</TextBody>
                                    <TextBody style={{ marginBottom: 10 }}>Generally, the longer the stay, the lower the nightly rate. For example, minimum month long stays and year long stays. But this is up to your discretion.</TextBody>
                                    <TextBody>This price calculator does not include service fees for platform that will be reduced from your payout. The Hookhub service fee per booking is 10%. Adjust your nightly price so you can make the exact amount you'd like.</TextBody>

                                    <CustomButton hideBorder={true} width={mobileView ? width / 1.5 : width / 2.5} onPress={() => setShowExplanation(false)}><TextBody style={{ textDecorationLine: "underline" }}>hide explanation</TextBody></CustomButton>
                                </View>
                            ) : (
                                <CustomButton hideBorder={true} width={mobileView ? width / 1.2 : width / 2.5} height={50} onPress={() => setShowExplanation(true)}><TextBody style={{ textDecorationLine: "underline", textAlign: "center" }}>* This is just an example. View Explanation of options and pricing</TextBody></CustomButton>
                            )}
                        </View>
                    </View>
                    {/* //////////////////////////////// */}

                    <View style={[globalStyles.center, { marginBottom: 50 }]}>
                        <View style={mobileView ? globalStyles.centerColumn : globalStyles.center}>
                            <Text>Ready to Host your Property?</Text>
                            <HostSpaceButton authed={authenticated} navigate={() => navigation.navigate("HostCreateSpaceNavigator")} setSignUpModalOpen={() => setSignUpModalOpen(true)} mobileView={mobileView} />
                        </View>
                    </View>

                    {/* Image: 'Rent out your unused driveway or land space' */}
                    <View>
                        {width < 500 ? (
                            <>
                                <Image
                                    style={{ width: width / 1.2, height: (width / 1.2) * .8, marginTop: 20 }}
                                    source={{ uri: `${cloudfrontURLStaticImages}hoststatement.png` }}
                                />
                            </>
                        ) : (
                            <ImageBackground
                                source={{ uri: `${cloudfrontURLStaticImages}splashScreen2.jpg` }}
                                resizeMode="cover"
                                style={[styles.image, { marginVertical: 25 }]}
                            >
                                <View style={[globalStyles.spaceBetween, { width: width }]}>
                                    <Image
                                        style={{ width: width / 3, height: (width / 3), marginLeft: "15%" }}
                                        source={{ uri: `${cloudfrontURLStaticImages}hostlist.png` }}
                                    />
                                    <Image
                                        style={{ width: width / 3, height: (width / 3), marginRight: "10%" }}
                                        source={{ uri: `${cloudfrontURLStaticImages}hoststatement.png` }}
                                    />
                                </View>
                            </ImageBackground>
                        )}
                    </View>
                    {/* //////////////////////////////// */}
                </View>

                {/* Review Display */}
                <ReviewDisplay
                    width={mobileView ? width / 1.05 : width / 2}
                    reviewerAvatar={"trish.jpg"}
                    reviewerText={`Easy as can be and the support team is always available and ready to help with anything. I've already made over $ 3,000 with one space.`}
                    reviewerSignature={"Trish, Host since 2023"}
                />
                {/* //////////////////////////////// */}

                <CustomDivider marginVertical={50} />

                <ComparisonToCompetitorsChart />

                {/* Image */}
                {width < 500 && (
                    <View style={{ marginVertical: 20 }}>
                        <ImageBackground
                            source={{ uri: `${cloudfrontURLStaticImages}splashScreen2.jpg` }}
                            resizeMode="cover"
                            style={[{ marginBottom: 30, marginTop: 50, width: width, height: "auto" }]}
                        >
                            <View style={[globalStyles.centerColumn, { width: width }]}>
                                <Image
                                    style={{ width: width / 1.2, height: (250), }}
                                    source={{ uri: `${cloudfrontURLStaticImages}hostlist.png` }}
                                />
                            </View>
                        </ImageBackground>
                    </View>
                )}
                {/* //////////////////////////////// */}

                <LearnMoreHostSplash />

                <ImageBackground
                    source={{ uri: `${cloudfrontURLStaticImages}aboutImage0.png` }}
                    resizeMode="cover"
                    style={[styles.image, { marginVertical: 50 }]}
                >
                    <View style={[mobileView ? globalStyles.centerColumn : globalStyles.center, { marginVertical: 40, width: width }]}>
                        <View>
                            <Image
                                style={{ width: width < 500 ? width / 1.2 : mobileView ? width / 2 : width / 3, height: (width < 500 ? width / 1.2 : mobileView ? width / 2 : width / 3) * .8, }}
                                source={{ uri: `${cloudfrontURLStaticImages}hostcalltoaction.png` }}
                            />
                        </View>
                        <View style={[globalStyles.flexEndColumn, { marginRight: "5%", marginBottom: "5%" }]}>
                            <HostSpaceButton authed={authenticated} navigate={() => navigation.navigate("HostCreateSpaceNavigator")} setSignUpModalOpen={() => setSignUpModalOpen(true)} mobileView={mobileView} />
                        </View>
                    </View>
                </ImageBackground>

                {!mobileView ? (
                    <View style={{ flexDirection: "row", marginTop: 30 }}>
                        <Image
                            style={{ width: width / 3.25, height: (width / 3.25) * .8, margin: mobileView ? 2 : 10, borderRadius: 5 }}
                            source={{ uri: `${cloudfrontURLStaticImages}aboutImage0.png` }}
                        />
                        <Image
                            style={{ width: width / 3.25, height: (width / 3.25) * .8, margin: mobileView ? 2 : 10, borderRadius: 5 }}
                            source={{ uri: `${cloudfrontURLStaticImages}aboutImage2.png` }}
                        />
                        <Image
                            style={{ width: width / 3.25, height: (width / 3.25) * .8, margin: mobileView ? 2 : 10, borderRadius: 5 }}
                            source={{ uri: `${cloudfrontURLStaticImages}aboutImage1.png` }}
                        />
                    </View>
                ) : <></>}

                <HelpForm authed={authenticated} />

                <SignUpModal
                    authenticate={() => authenticate()}
                    modalVisible={signUpModalOpen}
                    setModalVisible={() => setSignUpModalOpen(false)}
                    mobileView={mobileView}
                    width={width}
                />
                <View style={[globalStyles.center, { width: mobileView ? width / 1.2 : width / 1.35, margin: mobileView ? 15 : 80 }]}>
                    <CustomSection backgroundColor={colors.mellowOrange} customWidth={mobileView ? width / 1.1 : width / 1.3}>
                        <Header textAlign="center" color={colors.amplifyNeutral90} level={mobileView ? .9 : 1.3} text="Any problems, questions or assistance you need- we are here for you. Reach out at anytime and we will be there to help with every step along the way." />
                    </CustomSection>
                </View>
            </View>
            <FooterView />
        </ScrollView >
    );
}

const styles = StyleSheet.create({
    container: {
        margin: 20,
    },
    centeredRow: {
        alignItems: "center",
        justifyContent: "space-between",
        alignSelf: "center",
        flexDirection: "row",
        width: 170
    },
    image: {
        flex: 1,
        justifyContent: "center"
    },
    buttonText: {
        color: colors.amplifyNeutral90,
        fontWeight: "500",
        fontSize: 17,
    },
    helpButton: {
        borderColor: "grey",
        borderRadius: 10,
        width: 100,
        borderWidth: 1,
        backgroundColor: "grey",
        alignItems: "center",
        borderBottomWidth: 2,
        justifyContent: "center",
        alignSelf: "center",
        height: 40,
    },
    view: {
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center"
    },
});

export default HostSplashScreen;
