import React, { useState } from "react";
import {
    Pressable,
    StyleSheet,
    Text,
    View
} from "react-native";
import { setStringAsync } from 'expo-clipboard';
import CustomModal from "../CustomElements/CustomModal";
import { colors } from "../../styles/colors";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import CustomButton from "../CustomElements/CustomButton";
import { globalStyles } from "../../styles/styles";

export const ShareModal = ({
    modalVisible,
    setModalVisible,
    width,
    mobileView,
    shareLink
}) => {
    const [copied, setCopied] = useState(false)

    const copyToClipboard = async () => {
        await setStringAsync(shareLink);
        setCopied(true)
        setTimeout(() => setCopied(false), "4000")
    };

    return (
        <CustomModal
            modalVisible={modalVisible}
            setModalVisible={() => setModalVisible(!modalVisible)}
            modalWidth={mobileView ? width / 1.2 : width / 1.4}
        >
            <View style={styles.centeredView}>
                <Text style={{ fontSize: 17, textAlign: "center", margin: mobileView ? 10 : 20 }}>
                    Share this Space
                </Text>
                <Pressable onPress={() => copyToClipboard()}>
                    <View style={[globalStyles.centerColumn, {marginTop: 15}]}>
                        {copied ? (
                            <View style={globalStyles.center}>
                                <Ionicons
                                    name="checkmark-circle-sharp"
                                    size={25}
                                    color={colors.amplifyNeutral90}
                                    marginLeft={10}
                                />
                                <Text style={styles.copyLinkText}>Copied!</Text>
                            </View>
                        ) : (
                            <CustomButton
                                height={35}
                                width={150}
                                backgroundColor={colors.mellowOrange}
                                onPress={() => copyToClipboard()}
                            >
                                <View style={globalStyles.center}>
                                    <FontAwesome5
                                        name="copy"
                                        size={25}
                                        color={colors.amplifyNeutral90}
                                        marginLeft={10}
                                    />
                                    <Text style={styles.copyLinkText}>Copy Link</Text>
                                </View>
                            </CustomButton>

                        )}
                    </View>
                </Pressable>
            </View>
        </CustomModal >

    );
};

const styles = StyleSheet.create({
    linkText: {
        fontSize: 17, textAlign: "center",
        margin: 20,
        backgroundColor: "#fff",
        padding: 8,
        flexShrink: 1
    },
    copyLinkText: {
        fontSize: 17,
        marginLeft: 10
    },
});
