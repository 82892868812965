import React, { useContext, useEffect } from "react";
import { Pressable, Text, useWindowDimensions, ScrollView } from "react-native";
import { colors } from "../../styles/colors";
import {
  AntDesign,
  MaterialCommunityIcons,
  FontAwesome5,
  Feather,
} from "@expo/vector-icons";
import Flex from "../../components/CustomElements/Flex"
import Header from "../../components/CustomElements/Header"
import { useNavigation, useRoute } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { BookingsContext } from "../../utils/BookingsContextProvider";
import FixedHeightCard from "../../components/CustomElements/FixedHeightCard";
import BackButton from "../../components/other/BackButton";
import { UserDataContext } from "../../utils/UserContextProvider";
import { globalStyles } from "../../styles/styles";
import { View } from "react-native";
import CustomButton from "../../components/CustomElements/CustomButton";
import { Auth } from "aws-amplify";
import { RESETAPP } from "../../redux/reducers/reducers";
import FooterView from "../../components/other/FooterView";

const AccountCard = ({ title, subtext, icon, navigateHere, mobileView, width }) => {

  return (
    <FixedHeightCard
      marginRight={10}
      marginLeft={10}
      marginTop={mobileView ? 13 : 20}
      width={!!mobileView ? width / 1.2 : width / 3.5}
      height={!mobileView ? 165 : title === "Payments & Income" ? 160 : 140}
      textAlign="center"
      alignContent="center"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Pressable onPress={navigateHere} style={{ margin: 20, textAlign: "center" }}>
        <Flex
          direction="column"
          textAlign="left"
          width={!!mobileView ? width / 1.4 : width / 4}
        >
          {icon}
        </Flex>
        <Flex
          direction="column"
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          width={!!mobileView ? width / 1.4 : width / 4}
        >
          <Header
            color="#4c4e52"
            text={title}
          />
          <Text style={{textAlign: "center"}}>{subtext}</Text>
        </Flex>
      </Pressable>
    </FixedHeightCard>
  );
};

const AccountGrid = ({ mobileView, navigation }) => {
  const { width } = useWindowDimensions();

  return (
    <Flex
      direction="column"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      marginBottom={50}
      marginTop={10}
    >
      <AccountCard
        width={width}
        mobileView={mobileView}
        icon={<AntDesign name="profile" size={24} color="#616A6B" />}
        navigateHere={() => navigation.navigate("profile")}
        title="Profile"
        subtext="Profile and personal details"
      />
      <AccountCard
        width={width}
        mobileView={mobileView}
        icon={<FontAwesome5 name="plug" size={24} color="#616A6B" />}

        navigateHere={() => navigation.reset({
          index: 0,
          routes: [
            {
              name: "trips",
              screen: "trips"
            }
          ]
        })}
        title="My Trips"
        subtext="Your past and future trips"
      />
      <AccountCard
        width={width}
        mobileView={mobileView}
        icon={<FontAwesome5 name="plug" size={24} color="#616A6B" />}
        navigateHere={() => navigation.reset({ routes: [{ name: 'hostaccount' }] })}
        title="My Hosting"
        subtext="Check on your spaces"
      />
      <AccountCard
        width={width}
        mobileView={mobileView}
        icon={
          <MaterialCommunityIcons
            name="shield-account"
            size={24}
            color="#616A6B"
          />
        }
        navigateHere={() => navigation.navigate("paymentsettings")}
        title="Payments & Income"
        subtext="Set hosting payout account and stripe portal"
      />
      <Flex
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        direction={!!mobileView ? 'column' : 'row'}
      >
        {/* <AccountCard
        mobileView={mobileView}
          icon={<Ionicons name="notifications" size={24} color="#616A6B" />}
          navigateHere="NotificationSettings"
          title="Notifications"
          subtext="Set preferences for notifications"
        /> */}
        {/* <AccountCard
        mobileView={mobileView}
          icon={<Entypo name="location" size={24} color="#616A6B" />}
          navigateHere="LocationPreferences"
          title="Location preferences"
          subtext="Set your language, currency and default timezone"
        /> */}
      </Flex>
    </Flex>
  );
};

function ProfileScreen() {
  const { mobileView } = useSelector(state => state.currentUser);
  const navigation = useNavigation();
  const { width } = useWindowDimensions()
  const route = useRoute();
  const dispatch = useDispatch();
  const { user } = useContext(UserDataContext);
  const { setUserData } = user;
  const clearUserData = () => setUserData({});

  function logout() {
    clearUserData();
    dispatch(RESETAPP());
    Auth.signOut();
  }

  useEffect(() => {
    if (route?.params && route?.params?.screen === 'profile') {
      navigation.navigate("profile")
    }
  }, []);

  return (
    <ScrollView style={{ marginTop: mobileView ? 0 : 30 }}>
      <View style={[globalStyles.spaceBetween]}>
        <Header color={colors.amplifyNeutral80} text="My Account" marginTop={10} marginLeft={10}/>
        <CustomButton
          width={90}
          marginTop={mobileView ? 10 : 15}
          marginBottom={5}
          marginRight={10}
          backgroundColor={colors.brandGrey}
          onPress={() => logout()}
        >
          <View style={[globalStyles.spaceBetween, { paddingHorizontal: 5 }]}>
            <Text>Sign out</Text>
            <Feather name="log-out" size={17} color="#000" />
          </View>
        </CustomButton>
      </View>
      <AccountGrid mobileView={mobileView} navigation={navigation} />
      <FooterView />
    </ScrollView>
  );
}

export default ProfileScreen;
