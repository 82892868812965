import React from "react";
import {
    Text,
    StyleSheet,
    View,
    Pressable
} from "react-native";
import { AntDesign, FontAwesome, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import Header from "../CustomElements/Header"
import { colors } from "../../styles/colors"
import { ImageGrid } from "./ImageGrid";
import CustomButton from "../CustomElements/CustomButton";
import { globalStyles } from "../../styles/styles";
import BackButton from "../other/BackButton";
import CustomButtonUnderlined from "../CustomElements/CustomButtonUnderlined";

const HeaderButtons = ({ favorited, updateFavorite, setShareModalOpen, mobileView = false }) => {
    return (
        <>
            <View style={[styles.headerButtons]}>
                {mobileView ? (
                    <Pressable onPress={() => setShareModalOpen(true)}>
                        <View style={{ position: "absolute", top: 10, left: 10 }}>
                            <View style={{ backgroundColor: colors.amplifyNeutral80, height: 18, width: 18 }} />
                        </View>
                        <MaterialCommunityIcons name="share-circle" size={38} color="white" />
                    </Pressable>
                ) : (
                    <CustomButtonUnderlined
                        width={mobileView ? 30 : 90}
                        height={35}
                        marginRight={10}
                        backgroundColor={"#fff"}
                        border={colors.amplifyNeutral90}
                        onPress={() => setShareModalOpen(true)}
                    >
                        <View style={globalStyles.center}>
                            <FontAwesome name="share-square-o" size={18} color={colors.amplifyNeutral90} />
                            <Text style={{ marginLeft: 4, borderBottomWidth: .4 }}>Share</Text>
                        </View>
                    </CustomButtonUnderlined>
                )}
                <View style={{ marginLeft: 5 }}>
                    {mobileView ? (
                        <Pressable onPress={() => updateFavorite(!favorited)}>
                            <View style={{ position: "absolute", top: 10, left: 10 }}>
                                <View style={{ backgroundColor: favorited ? colors.orange : colors.amplifyNeutral80, height: 21, width: 21 }} />
                            </View>
                            <Ionicons name="heart-circle-sharp" size={42} color={colors.white} />
                        </Pressable>
                    ) : (
                        <CustomButton
                            width={45}
                            height={35}
                            hideBorder
                            backgroundColor={"#fff"}
                            border={colors.amplifyNeutral90}
                            onPress={() => updateFavorite(!favorited)}
                        >
                            <View style={globalStyles.center}>
                                <AntDesign name={favorited ? "heart" : "hearto"} size={18} color={favorited ? colors.orange : colors.amplifyNeutral80} />
                                <Text style={{ marginLeft: 4, borderBottomWidth: .4 }}>{favorited ? "Saved" : "Save"}</Text>
                            </View>
                        </CustomButton>
                    )}
                </View>
            </View>
        </>
    )
}
const DetailedSpaceHeader = ({ mobileView, images, title, setShareModalOpen, imLoggedIn, updateFavorite, favorited, width }) => {

    return (
        <>
            {mobileView ? (
                <View style={[globalStyles.center, { width: width / 1.05 }]}>
                    {images && <ImageGrid images={images} />}
                    <View style={{ position: "absolute", top: 2, left: 0 }}>
                        <View style={[globalStyles.spaceBetween]}>
                            <BackButton goBack={false} />
                        </View>
                    </View>
                    <View style={{ position: "absolute", top: 2, right: 0 }}>
                        <View style={[globalStyles.spaceBetween]}>
                            <HeaderButtons mobileView={true} favorited={favorited} updateFavorite={() => updateFavorite(!favorited)} setShareModalOpen={() => setShareModalOpen(true)} imLoggedIn={imLoggedIn} />
                        </View>
                    </View>
                </View>
            ) : (
                <>
                    <View style={[globalStyles.center, { marginBottom: 10, width: width / 1.1 }]}>
                        <View style={{ width: width / 2 }}>
                            <Header level={1.4} text={title} padding={0} marginTop={20} marginBottom={10} color={colors.amplifyNeutral80} />
                        </View>
                        <HeaderButtons favorited={favorited} updateFavorite={() => updateFavorite(!favorited)} setShareModalOpen={() => setShareModalOpen(true)} imLoggedIn={imLoggedIn} />
                    </View >
                    {images && <ImageGrid images={images} />}
                </>
            )
            }
        </>
    );
};

export default DetailedSpaceHeader;


const styles = StyleSheet.create({
    headerButtons: {
        flexDirection: "row",
        justifyContent: "flex-end",
        alignContent: "center",
        alignItems: "center",
        flex: 1
    },
});