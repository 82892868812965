import Flex from "../CustomElements/Flex"
import { useNavigation } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import CustomButton from "../CustomElements/CustomButton";
import { Pressable, Text } from "react-native";
import { colors } from "../../styles/colors"
import { useSelector } from "react-redux";
import { useContext } from "react";
import { UserDataContext } from "../../utils/UserContextProvider";
import { View } from "react-native";


const BackButton = ({
    goBack = true,
}) => {
    const navigation = useNavigation();
    const { mobileView } = useSelector(state => state.currentUser);
    const loggedInUser = useContext(UserDataContext);

    return (
        <Flex
            alignContent="flex-start"
            justifyContent="flex-start"
            alignSelf="flex-start"
            marginTop={mobileView ? 5 : 10}
            marginLeft={mobileView ? 5 : 15}
            marginBottom={mobileView ? 5 : 10}
        >
            {mobileView ? (
                <Pressable onPress={() => goBack ? navigation.goBack() : navigation.reset({ routes: [{ name: !!loggedInUser ? 'home' : 'explore' }] })}>
                    <View style={{ position: "absolute", top: 10, left: 10 }}>
                        <View style={{ backgroundColor: colors.amplifyNeutral80, height: 18, width: 18 }} />
                    </View>
                    <Ionicons name="chevron-back-circle-sharp" size={40} color="white" />
                </Pressable>
            ) : (
                <CustomButton
                    marginLeft={0}
                    width={mobileView ? 90 : 95}
                    height={35}
                    backgroundColor={colors.amplifyNeutral20}
                    onPress={() => goBack ? navigation.goBack() : navigation.reset({ routes: [{ name: !!loggedInUser ? 'home' : 'explore' }] })}
                >
                    <Flex justifyContent="center" alignContent="center" alignItems="center">
                        <AntDesign name="caretleft" size={19} color="grey" />
                        <Text style={{ marginLeft: 5, fontSize: 15 }}>back</Text>
                    </Flex>
                </CustomButton>
            )}
        </Flex>
    )
}

export default BackButton;