import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, Pressable } from "react-native";
import { Fontisto, Ionicons } from '@expo/vector-icons';
import ContactView from "../space/ContactView";
import { getSpaceAndPropertyDetails } from "../../api/space";
import { formatMonthDay, formatYear } from "../../utils/formatDate";
import Flex from "../CustomElements/Flex";
import Card from "../CustomElements/Card";
import Header from "../CustomElements/Header";
import { colors } from "../../styles/colors"
import { createOpenLink } from 'react-native-open-maps';
import PaymentDatesDisplay from "./PaymentDatesDisplay";
import CustomButton from "../CustomElements/CustomButton";
import Badge from "../CustomElements/Badge";
import CustomDivider from "../CustomDivider";

const BookingRenterView = ({ bookingToDisplay, thisIsARequest = false, width, mobileView, closeModal, cancelled = false, spaceTitle, propertyTitle }) => {
    const {
        spaceID,
        hostID,
        renterID,
        cancelledDate,
        payMonthly,
        numberOfMonths,
        monthlyPaymentAmount,
        nextPaymentsSchedule,
        spaceCity,
        spaceState,
        rentalType,
        check_in,
        check_out,
        bookingRequest,
        renterTotalBookingCost
    } = bookingToDisplay;
    const [checkIn, setCheckIn] = useState(null);
    const [checkOut, setCheckOut] = useState(null);
    const [spaceDetails, setspaceDetails] = useState(null);
    const [location, setLocation] = useState(null);
    const [checkInYear, setCheckInYear] = useState(null);
    const [checkOutYear, setCheckOutYear] = useState(null);
    const [checkInTime, setcheckInTime] = useState(null);
    const [checkOutTime, setcheckOutTime] = useState(null);
    const [checkInInstructions, setcheckInInstructions] = useState(null);
    const [spaceRules, setspaceRules] = useState(null);
    const [quietHoursStart, setquietHoursStart] = useState(null);
    const [quietHoursEnd, setquietHoursEnd] = useState(null);
    const stayType = rentalType === 'shortterm' ? 'Vacation' : rentalType === 'longterm' || rentalType === 'yearly' ? 'Long Term' : 'Storage'

    const formatDates = () => {
        const start = formatMonthDay(check_in);
        const startYear = formatYear(check_in);
        const end = cancelled ? formatMonthDay(cancelledDate) : formatMonthDay(check_out);
        const endYear = cancelled ? formatYear(cancelledDate) : formatYear(check_out);
        setCheckIn(start);
        setCheckInYear(startYear);
        setCheckOut(end);
        setCheckOutYear(endYear);
    };

    const fetchSpaceDetails = async () => {
        const space = await getSpaceAndPropertyDetails(spaceID);
        if (!!space) {
            const { property, check_in_time, check_out_time, check_in_instructions, space_rules, quiet_hours_start, quiet_hours_end } = space;
            setspaceDetails(space);
            !!check_in_time && setcheckInTime(check_in_time);
            !!check_out_time && setcheckOutTime(check_out_time);
            !!quiet_hours_start && setquietHoursStart(quiet_hours_start);
            !!quiet_hours_end && setquietHoursEnd(quiet_hours_end);
            !!check_in_instructions && setcheckInInstructions(check_in_instructions);
            !!space_rules && setspaceRules(space_rules);
            setLocation(`${property?.address?.address} ${property?.address?.cityName}, ${property?.address?.stateName}`);
        }
    };

    const handleOpenMaps = createOpenLink({ end: location })

    useEffect(() => {
        formatDates();
    }, []);

    useEffect(() => {
        fetchSpaceDetails();
    }, []);

    return (
        <Flex
            direction="column"
            width={width / 1.3}
            textAlign="left"
            marginBottom={15}
        >
            <View>
                <Badge margin={2} variation={cancelled ? "red" : "info"} width={175}>
                    <Text style={{ fontSize: 16, lineHeight: 22, color: cancelled ? colors.amplifyNeutral20 : "black" }}>
                        {cancelled ? 'booking cancelled' : thisIsARequest ? "BOOKING REQUEST" : stayType}
                    </Text>
                </Badge>
            </View>
            <Flex
                justifyContent="center"
                alignItems="center"
                direction="column"
                width={width / 1.3}
            >
                {spaceTitle === propertyTitle ? (
                    <Header level={1.2} text={spaceTitle} />
                ) : (
                    <>
                        <Header level={1.2} text={propertyTitle} />
                        <Header level={1.1} text={spaceTitle} padding={0} />
                    </>
                )}
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    width={!!mobileView ? width / 1.35 : width / 1.5}
                    marginTop={20}
                >
                    <Card
                        backgroundColor={colors.mellowOrange}
                        alignItems="center"
                        justifyContent="center"
                        alignContent="center"
                        width={mobileView ? width / 3.2 : width / 3.5}
                        textAlign="center"
                    >
                        <Text style={styles.checkText}>{!mobileView && 'CHECK '}IN</Text>
                        <Flex
                            alignItems="center"
                            textAlign="center"
                            justifyContent="center"
                            width={mobileView ? width / 3.2 : width / 3.5}
                            alignContent="center"
                        >
                            <Header textAlign="center" color={colors.amplifyNeutral80} level={mobileView ? 1.2 : 1.4} text={checkIn} />
                        </Flex>
                        <Text>{checkInYear}</Text>
                        {!!checkInTime && (
                            <Header color={colors.amplifyNeutral80} level={1} text={"after " + checkInTime} />
                        )}
                    </Card>
                    <Card
                        backgroundColor={colors.mellowOrange}
                        alignItems="center"
                        width={width / 3.5}
                        textAlign="center"
                        border="#4c4e52"
                    >
                        <Text style={styles.checkText}>{!mobileView && 'CHECK '}OUT</Text>
                        <Flex
                            alignItems="center"
                            width={width / 3.5}
                            textAlign="center"
                            justifyContent="center"
                            alignContent="center"
                        >
                            <Header textAlign="center" color={colors.amplifyNeutral80} level={mobileView ? 1.2 : 1.4} text={checkOut} />
                        </Flex>
                        <Text>{checkOutYear}</Text>
                        {!!checkOutTime && (
                            <Header color={colors.amplifyNeutral80} level={1} text={"before " + checkOutTime} />
                        )}
                    </Card>
                </Flex>
                {spaceDetails && !thisIsARequest && (
                    <>
                        <Card
                            backgroundColor={colors.mellowOrange}
                            alignItems="center"
                            textAlign="center"
                            border={colors.amplifyNeutral60}
                            width={width / 1.5}
                            marginTop={15}
                            marginBottom={15}
                        >
                            <Text style={styles.checkText}>LOCATION</Text>
                            <Text style={{ fontSize: 17 }}>{location}</Text>
                            <CustomButton
                                width={150}
                                marginTop={15}
                                marginBottom={5}
                                backgroundColor={colors.hookhubOrangeDark}
                                onPress={handleOpenMaps}
                            >
                                <View style={styles.openMapsButton}>
                                    <Text style={styles.buttonText}>open map</Text>
                                    <Ionicons name="open-outline" size={24} color="black" />
                                </View>
                            </CustomButton>
                        </Card>
                        {checkInInstructions && (
                            <Card
                                backgroundColor={colors.hookhubOrangeDark}
                                alignItems="center"
                                textAlign="center"
                                border={colors.amplifyNeutral60}
                                padding={10}
                                width={width / 1.5}
                            >
                                <Header level={1} text="check-in instructions" />
                                <Text style={{ fontSize: 17 }}>{checkInInstructions}</Text>
                            </Card>
                        )}
                        {spaceDetails?.wifi_available && spaceDetails?.wifi_network_name && spaceDetails?.wifi_password && (
                            <Card
                                backgroundColor={colors.hookhubOrangeDark}
                                alignItems="center"
                                textAlign="center"
                                border={colors.amplifyNeutral60}
                                width={width / 1.5}
                            >
                                <Text style={styles.checkText}>WiFi</Text>
                                <Text style={{ fontSize: 17 }}>network name: {spaceDetails.wifi_network_name}</Text>
                                <Text style={{ fontSize: 17 }}>password: {spaceDetails.wifi_password}</Text>
                            </Card>
                        )}
                        {quietHoursStart && quietHoursEnd && (
                            <Card
                                backgroundColor={colors.hookhubOrangeDark}
                                alignItems="center"
                                justifyContent="center"
                                alignContent="center"
                                width={width / 1.5}
                                textAlign="center"
                            >
                                <Text style={styles.checkText}>QUIET HOURS</Text>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ justifyContent: "center", alignContent: "center", alignItems: "center", flexDirection: 'column' }}>
                                        <Text>START</Text>
                                        <Header textAlign="center" color={colors.amplifyNeutral90} level={1.1} text={quietHoursStart} />
                                    </View>
                                    <View style={{ justifyContent: "center", alignContent: "center", alignItems: "center", flexDirection: 'column' }}>
                                        <Text>END</Text>
                                        <Header textAlign="center" color={colors.amplifyNeutral90} level={1.1} text={quietHoursEnd} />
                                    </View>
                                </View>
                            </Card>
                        )}
                        {spaceRules && (
                            <View>
                                <CustomDivider />
                                <Text style={styles.checkText}>SPACE RULES</Text>
                                <Text style={{ fontSize: 17 }}>{spaceRules}</Text>
                            </View>
                        )}
                    </>
                )}
                {payMonthly ? (
                    <PaymentDatesDisplay
                        width={width}
                        mobileView={mobileView}
                        numberOfMonths={numberOfMonths}
                        monthlyPrice={monthlyPaymentAmount}
                        hostView={false}
                        stillPaymentsComingBoolean={!!nextPaymentsSchedule.length}
                        paymentDates={nextPaymentsSchedule}
                        formatted={true}
                    />
                ) : (
                    <Card
                        backgroundColor={colors.mellowOrange}
                        alignItems="center"
                        textAlign="center"
                        marginTop={10}
                        width={width / 1.5}
                    >
                        <Text style={styles.checkText}>TOTAL COST</Text>
                        <Flex marginBottom={4} marginTop={10} alignContent="center" justifyContent="center" width={mobileView ? width / 1.4 : 400}>
                            <Fontisto name="dollar" size={24} color={colors.amplifyNeutral80} />
                            <Header level={1.2} text={Number(renterTotalBookingCost).toLocaleString("en-US", { minimumFractionDigits: 2 })} />
                        </Flex>
                    </Card>
                )}
                <ContactView
                    viewWidth={150}
                    centerView={true}
                    closeModal={() => closeModal()}
                    ownerID={hostID}
                    hosteeID={renterID}
                    spaceID={spaceID}
                    spaceTitle={bookingRequest.spaceTitle}
                    propertyID={bookingRequest.propertyID}
                    propertyTitle={bookingRequest.propertyTitle}
                    spaceState={spaceState}
                    spaceCity={spaceCity}
                    contactToHost={true}
                />
            </Flex >
        </Flex >
    );
};

export default BookingRenterView;


const styles = StyleSheet.create({
    note: {
        textAlign: "center",
        backgroundColor: colors.brandGrey,
        borderRadius: 20,
        padding: 7,
        margin: 5,
        paddingLeft: 20,
        paddingRight: 20
    },
    checkText: {
        fontSize: 17,
        marginBottom: 5
    },
    cancelled: {
        textAlign: "center",
        backgroundColor: "#f15454",
        borderRadius: 20,
        padding: 7,
        margin: 5
    },
    openMapsButton: {
        flexDirection: 'row',
        alignContent: "space-between",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center"
    },
    buttonText: {
        fontSize: 17,
        marginRight: 10
    }
});