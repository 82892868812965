import React, { useState, useEffect } from "react";
import { StyleSheet, Text, useWindowDimensions, View } from "react-native";
import HostOnboarding from "../../components/host/HostOnboarding";
import Flex from "../../components/CustomElements/Flex"
import { useDispatch, useSelector } from 'react-redux';
import { setAdditionalSpacesAlteredData, setSpaceTitle } from "../../redux/actions/HostOnboardingActions";
import CustomTextInput from "../../components/CustomElements/CustomTextInput";
import Header from "../../components/CustomElements/Header";
import CustomButton from "../../components/CustomElements/CustomButton";
import { Ionicons } from "@expo/vector-icons";
import { colors } from "../../styles/colors";
import { UUID } from "../../utils/UUID";
import SelectField from "../../components/CustomElements/SelectField";
import InputCard from "../../components/CustomElements/InputCard";

const TitleInput = ({ title, settitle, textLength, error, additionalSpacesBoolean, newProperty }) => {
  const { mobileView } = useSelector(state => state.currentUser);
  const { width } = useWindowDimensions();

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      {additionalSpacesBoolean && <Header text="Property Name" />}
      <CustomTextInput
        height={mobileView ? 80 : 50}
        multiline={true}
        width={mobileView ? width / 1.2 : 500}
        placeholder="ex: Cozy shaded space with all amenities"
        errorMessage="There is an error"
        value={title}
        marginBottom={4}
        onChangeText={(e) => settitle(e)}
        disabled={!newProperty}
      />
      <Text style={{ color: error ? 'red' : 'black', marginBottom: 20 }}>{textLength}/100</Text>
    </Flex>
  );
};

const SetTitle = ({ thisSpaceTitle, k, updateTitles, mobileView, width }) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(thisSpaceTitle)
  }, [thisSpaceTitle])

  const updateTitleValue = (v) => {
    setTitle(v)
    updateTitles(k, v)
  };

  return (
    <CustomTextInput
      multiline={true}
      width={mobileView ? width / 1.4 : width / 1.7}
      placeholder={`${k} space name...`}
      errorMessage="There is an error"
      value={title}
      marginBottom={4}
      onChangeText={(v) => updateTitleValue(v)}
    />
  )
};

function AddTitleScreen() {
  const { additionalSpacesBoolean, numberAdditionalSpaces, newProperty, existingSpaceTitles, existingSpaceID, existingSpaceTitleToUpdate } = useSelector(state => state.hostOnboarding);
  const dispatch = useDispatch();
  const { mobileView } = useSelector(state => state.currentUser);
  const { width } = useWindowDimensions();
  const [disabled, setDisabled] = useState(false);
  const [title, settitle] = useState(useSelector(state => state.hostOnboarding.title));
  const [additionalSpacesData, setAdditionalSpacesData] = useState(useSelector(state => state.hostOnboarding.additionalSpacesAlteredData));
  const [additionalTitleRows, setAdditionalTitleRows] = useState([]);
  const [textLength, settextLength] = useState(0);
  const [additionalSpacesTitleType, setAdditionalSpacesTitleType] = useState(useSelector(state => state.hostOnboarding.additionalSpacesTitleType));
  const [error, setError] = useState(false);
  const [originalSpaceTitleToUpdate, setOriginalSpaceTitleToUpdate] = useState('');
  const pageHeading = newProperty ? "Title of your Property" : `Add space to "${title}"`;

  const saveTitles = () => {
    dispatch(setSpaceTitle({
      title: title,
      existingSpaceTitleToUpdate: originalSpaceTitleToUpdate
    }));
    if (!!additionalSpacesBoolean) {
      dispatch(setAdditionalSpacesAlteredData({
        additionalSpacesAlteredData: additionalSpacesData,
        additionalSpacesTitleType: additionalSpacesTitleType
      }));
    }
  };

  const updateSpaceTitle = (key, v) => {
    let updatedTitles = { ...additionalSpacesData }
    updatedTitles[key] = {
      id: UUID(),
      title: v,
      description: '',
      distance_electric_hookup: 10,
      distance_water_hookup: 10,
      distance_sewage_hookup: 10,
      width: 10,
      length: 20,
      image: '',
      imagePreview: '',
      imageBlob: ''
    };
    setAdditionalSpacesData(updatedTitles)
  }

  const switchType = (type) => {
    setAdditionalSpacesTitleType(type);
  }

  useEffect(() => {
    if (!title) {
      setDisabled(true)
    } else if (title.length > 100) {
      setDisabled(true);
      setError(true);
    } else {
      settextLength(title.length);
      setDisabled(false);
      setError(false);
    }
  }, [title]);

  useEffect(() => {
    if (!!numberAdditionalSpaces) {
      let rows = [];
      let titleData = { ...additionalSpacesData };
      for (let i = 1; i <= Number(numberAdditionalSpaces); i++) {
        const spaceKey = i.length > 2 ? (`${i}`) : i.length > 1 ? (`0${i}`) : (`00${i}`);
        let titleString = '';
        if (additionalSpacesTitleType === "numerical") {
          titleString = spaceKey;
          titleData[spaceKey] = {
            'title': titleString,
            description: '',
            distance_electric_hookup: 0,
            distance_water_hookup: 0,
            distance_sewage_hookup: 0,
            width: 0,
            length: 0,
            id: UUID(),
            image: '',
            imagePreview: '',
            imageBlob: ''
          }
        } else {
          titleString = Object.keys(titleData).length && titleData[spaceKey] ? titleData[spaceKey]?.title : `space ${i}`;
          titleData[spaceKey] = {
            'title': titleString,
            description: '',
            distance_electric_hookup: 0,
            distance_water_hookup: 0,
            distance_sewage_hookup: 0,
            width: 0,
            length: 0,
            id: UUID(),
            image: '',
            imagePreview: '',
            imageBlob: ''
          }
        }
        rows.push({
          spaceKey: spaceKey,
          titleString: titleString
        })
      }
      !existingSpaceTitleToUpdate.length && setOriginalSpaceTitleToUpdate(title);
      setAdditionalTitleRows(rows);
      setAdditionalSpacesData(titleData);
    }
  }, [numberAdditionalSpaces, additionalSpacesTitleType]);

  return (
    <HostOnboarding
      heading={pageHeading}
      subtext={newProperty ? "" : "Highlight what makes your space special."}
      navigateF={newProperty ? "HostSpace" : "SetDistances"}
      navigateB="SingleOrMultiple"
      nextButtonText="Next"
      disabledNextButton={disabled}
      onClickNext={() => saveTitles()}
      required="Title"
    >
      {newProperty && (
        <TitleInput
          newProperty={newProperty}
          title={title}
          settitle={(v) => settitle(v)}
          textLength={textLength}
          error={error}
          additionalSpacesBoolean={additionalSpacesBoolean}
        />
      )}
      {!!additionalSpacesBoolean && (
        <InputCard
          headerText={"How would you like your Spaces named?"}
          required={true}
        >
          <Text>(numerical or custom)</Text>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            width={mobileView ? width / 1.34 : 500}
            marginBottom={20}
          >
            <CustomButton
              height={45}
              textAlign="flex-start"
              alignItems="flex-start"
              width={140}
              marginBottom={10}
              backgroundColor={additionalSpacesTitleType === 'numerical' ? colors.vibrantBlue : colors.amplifyNeutral20}
              border={additionalSpacesTitleType === 'numerical' ? colors.amplifyNeutral80 : "#4c4e52"}
              onPress={() => switchType('numerical')}
            >
              <View style={{ flexDirection: "row", paddingLeft: 10 }}>
                <Ionicons name={additionalSpacesTitleType === 'numerical' ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={colors.amplifyNeutral80} />
                <Text style={{ fontSize: 20, textAlign: "left", marginLeft: 4 }}>Numerical</Text>
              </View>
            </CustomButton>
            <CustomButton
              height={45}
              textAlign="flex-start"
              alignItems="flex-start"
              width={140}
              marginBottom={10}
              backgroundColor={additionalSpacesTitleType === 'custom' ? colors.vibrantBlue : colors.amplifyNeutral20}
              border={additionalSpacesTitleType === 'custom' ? colors.amplifyNeutral80 : "#4c4e52"}
              onPress={() => switchType('custom')}
            >
              <View style={{ flexDirection: "row", paddingLeft: 10 }}>
                <Ionicons name={additionalSpacesTitleType === 'custom' ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={colors.amplifyNeutral80} />
                <Text style={{ fontSize: 20, textAlign: "left", marginLeft: 4 }}>Custom</Text>
              </View>
            </CustomButton>
          </Flex>
          {additionalTitleRows.map((v, i) => (
            <SetTitle thisSpaceTitle={v.titleString} k={v.spaceKey} key={i} updateTitles={(key, v) => updateSpaceTitle(key, v)} mobileView={mobileView} width={width} />
          ))}
          {!newProperty && !!(existingSpaceTitles).length && (
            <View style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }}>
              <Text style={styles.thirdText}>My current spaces on this property:</Text>
              {existingSpaceTitles.length > 1 ? (
                <SelectField
                  displayOnly={true}
                  options={existingSpaceTitles}
                  label=""
                  value={existingSpaceTitles[0]}
                  width={!!mobileView ? width / 1.4 : 450}
                />
              ) : (
                <SetTitle thisSpaceTitle={originalSpaceTitleToUpdate} k={existingSpaceID} updateTitles={(key, v) => setOriginalSpaceTitleToUpdate(v)} mobileView={mobileView} width={width} />
              )}
            </View>
          )}
        </InputCard>
      )}
    </HostOnboarding>
  )
};

const styles = StyleSheet.create({
  thirdText: {
    textAlign: "left",
    padding: 10,
    marginTop: 20,
    fontSize: 17
  }
});

export default AddTitleScreen;