import React, { useEffect, useState, useContext } from "react";
import { StyleSheet, Text as ReactText, View } from "react-native";
import ContactView from "../space/ContactView";
import { LoadingComponent } from "../LoadingComponent";
import moment from "moment";
import { AntDesign } from '@expo/vector-icons';
import { API } from "aws-amplify";
import { updateBookingRequest } from "../../graphql/mutations";
import { BookingsContext } from "../../utils/BookingsContextProvider";
import { useSelector } from "react-redux";
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import Header from "../CustomElements/Header"
import { colors } from "../../styles/colors"
import CustomButton from "../CustomElements/CustomButton";
import CustomModal from "../CustomElements/CustomModal";
import CloseButtonUnderlined from "../CustomElements/CloseButtonUnderlined";
import { globalStyles } from "../../styles/styles";


const RentalSummary = ({ bookingToDisplay, width, mobileView, setModalVisible }) => {
    const {
        spaceID,
        hostID,
        renterID,
        spaceCity,
        spaceState,
        spaceTitle,
        rentalType,
        check_in,
        check_out,
        propertyID,
        propertyTitle
    } = bookingToDisplay;
    const [checkIn, setCheckIn] = useState(null);
    const [checkOut, setCheckOut] = useState(null);
    const [checkInYear, setCheckInYear] = useState(null);
    const [checkOutYear, setCheckOutYear] = useState(null);

    const formatDates = () => {
        const start = `${check_in.slice(5, 7)}/${check_in.slice(8, 10)}`;
        const startYear = `${check_in.slice(0, 4)}`;
        const end = `${check_out.slice(5, 7)}/${check_out.slice(8, 10)}`;
        const endYear = `${check_out.slice(0, 4)}`;
        setCheckIn(start);
        setCheckInYear(startYear);
        setCheckOut(end);
        setCheckOutYear(endYear);
    };

    useEffect(() => {
        formatDates();
    }, []);

    return (
        <View style={globalStyles.centerColumn}>
            {!!checkIn && (
                <View style={globalStyles.centerColumn}>
                    <Flex
                        justifyContent="space-between"
                        alignItems="space-between"
                        alignContent="space-between"
                        width={width / 1.2}
                        padding={30}
                    >
                        <ReactText style={{ fontSize: 17 }}>Pending, waiting for Host review</ReactText>
                    </Flex>
                    {spaceTitle === propertyTitle ? (
                        <Header text={spaceTitle} textAlign="center" level={1.3}></Header>
                    ) : (
                        <>
                            <Header textAlign="center" text={propertyTitle} padding={1} level={1.3}></Header>
                            <Header level={.9} text={spaceTitle} padding={1} marginBottom={10} textAlign="center"></Header>
                        </>
                    )}
<View>
                        <Card
                            backgroundColor={colors.mellowOrange}
                            alignItems="center"
                            width={200}
                            textAlign="center"
                        >
                            <Header level={1.5} color={colors.amplifyNeutral90} text={checkIn}></Header>
                            <ReactText>{checkInYear}</ReactText>
                        </Card>
                        <Card
                            backgroundColor={colors.mellowOrange}
                            alignItems="center"
                            width={200}
                            textAlign="center"
                        >
                            <Header level={1.5} color={colors.amplifyNeutral90} text={checkOut}></Header>
                            <ReactText>{checkOutYear}</ReactText>
                        </Card>
                    </View>
                    <View style={globalStyles.centerColumn}>
                        <ContactView
                            viewWidth={width / 1.5}
                            centerView={true}
                            ownerID={hostID}
                            hosteeID={renterID}
                            spaceID={spaceID}
                            propertyID={propertyID}
                            propertyTitle={propertyTitle}
                            spaceTitle={spaceTitle}
                            spaceState={spaceState}
                            spaceCity={spaceCity}
                            contactToHost={true}
                            closeModal={() => setModalVisible()}
                        />
                    </View>
                </View>
            )}
        </View>
    );
};

const RequestModal = ({ cancelledRequest, modalVisible, setModalVisible, bookingToDisplay, width, navigate }) => {
    const { bookingRequests } = useContext(BookingsContext);
    const { bookingRequestsArray, setBookingRequestsArray } = bookingRequests;
    const { spaceID, id } = bookingToDisplay;
    const changeHandler = v => setBookingRequestsArray(v);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [tooLate, setTooLate] = useState(false);
    const [areYouSure, setAreYouSure] = useState(false);
    const { mobileView } = useSelector(state => state.currentUser);

    useEffect(() => {
        if (modalVisible) {
            const checkIn = moment(bookingToDisplay.check_in, "YYYY-MM-DD");
            const now = moment();
            let hours = checkIn.diff(now, 'hours');
            setTooLate(hours < 24);
        }
    }, [modalVisible]);

    const cancelRequest = async () => {
        await API.graphql({
            query: updateBookingRequest,
            variables: {
                input: {
                    id: id,
                    cancelled: true
                },
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        })
            .then((d) => {
                cancelledRequest(id)
                const newArr = bookingRequestsArray.filter(function (el) { return el.id !== bookingToDisplay.id; });
                setLoading(false);
                changeHandler(newArr);
                setModalVisible(false);
                setAreYouSure(false)
            })
            .catch((e) => {
                console.log("Error cancelling Booking", e)
                setError("Error cancelling booking.");
                setLoading(false);
                closeThis();
            })
    };

    const closeThis = () => {
        setAreYouSure(false);
        setModalVisible(!modalVisible);
    };

    return (
        <CustomModal
            modalVisible={modalVisible}
            setModalVisible={() => setModalVisible(!modalVisible)}
            width={width / 1.2}
        >
            {areYouSure ? (
                <Flex direction="column" alignItems="center" padding={30}>
                    <Header level={2} text="Are you sure?"></Header>
                    <CustomButton
                        width={200}
                        backgroundColor={colors.hookhubOrangeDark}
                        color="#fff"
                        marginBottom={4}
                        border={colors.amplifyNeutral90}
                        onPress={() => cancelRequest()}
                        disabled={tooLate ? true : false}
                    >
                        <ReactText>Yes, cancel rental request</ReactText>
                    </CustomButton>
                    <CustomButton
                        width={200}
                        backgroundColor={colors.brandGrey}
                        color="#fff"
                        marginBottom={4}
                        border={colors.amplifyNeutral90}
                        onPress={() => closeThis()}
                        disabled={tooLate ? true : false}
                    >
                        <ReactText>close</ReactText>
                    </CustomButton>
                </Flex>
            ) : (
                <>
                    <RentalSummary mobileView={mobileView} bookingToDisplay={bookingToDisplay} width={width} setModalVisible={() => setModalVisible()} />
                    <Flex justifyContent="center" alignItems="center" alignContent="center" marginTop={25}>
                        {loading ? (
                            <LoadingComponent />
                        ) : (
                            <Flex direction="column" alignItems="center" justifyContent="center" >
                                {tooLate && <ReactText style={{ textAlign: "center", marginBottom: 20 }}>This booking can no longer be cancelled. Start date is too close or has passed.</ReactText>}
                                <Flex alignItems="center" justifyContent="center" direction="column">
                                    <CustomButton
                                        width={140}
                                        marginBottom={4}
                                        backgroundColor={colors.brightBlue}
                                        onPress={() => navigate(spaceID)}
                                    >
                                        <View style={globalStyles.center}>
                                            <ReactText style={[styles.buttonText]}>space</ReactText>
                                            <AntDesign name="caretright" size={16} color="#363636" />
                                        </View>
                                    </CustomButton>
                                    {!tooLate && (
                                        <CustomButton
                                            marginBottom={4}
                                            width={140}
                                            backgroundColor={colors.amplifyNeutral60}
                                            onPress={() => setAreYouSure(true)}
                                        >
                                            <ReactText style={styles.buttonText}>cancel request</ReactText>
                                        </CustomButton>
                                    )}
                                    <CloseButtonUnderlined onPress={() => setModalVisible(!modalVisible)} />
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                </>
            )}
        </CustomModal>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center"
    },
    buttonText: {
        fontSize: 16
    },
    modalView: {
        marginTop: 10,
        backgroundColor: "#c4c4c4",
        borderRadius: 10,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.35,
        shadowRadius: 4,
        elevation: 5,
        paddingBottom: 20
    },
    approveCustombutton: {
        borderRadius: 12,
        padding: 15,
        elevation: 5,
        backgroundColor: "#599cde",
        // "#4c7eb0",
        // 
        // backgroundColor: "#4b88c4",
        shadowColor: "grey",
        shadowOffset: {
            width: 1,
            height: 3,
        },
        shadowOpacity: 0.45,
        shadowRadius: 4,
    },
    denyCustombutton: {
        borderRadius: 12,
        backgroundColor: "#7a7a7a",
        shadowColor: "grey",
        height: 50,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 50,
        paddingRight: 50,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOpacity: 0.8,
        elevation: 6,
        shadowRadius: 15,
        shadowOffset: { width: 1, height: 13 },
    },
    closeCustomButton: {
        padding: 10,
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    denyTextStyle: {
        color: "#292929",
        textAlign: "center",
        fontWeight: "bold",
    },
    closeText: {
        fontWeight: "bold",
        textDecorationLine: "underline"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    title: {
        textAlign: "left",
        width: '100%',
        backgroundColor: "#2196F3"
    },
    note: {
        textAlign: "center",
        backgroundColor: "#d6d6d6",
        borderRadius: 20,
        padding: 7
    },
});

export default RequestModal;
