import React, { useEffect, useState } from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  Platform,
  ImageBackground,
  Image
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import PropertiesGrid from "../components/space/PropertiesGrid";
import { Ionicons } from "@expo/vector-icons";
import Geocode from "react-geocode";
import getEnvVars from '../../environment';
const { geocodeAPIkey } = getEnvVars();
import { GestureHandlerRootView, ScrollView } from 'react-native-gesture-handler';
Geocode.setApiKey(geocodeAPIkey);
import { setNotificationViewed, setSpaceStayType } from "../redux/actions/CurrentUserActions";
import Badge from "../components/CustomElements/Badge";
import AllSpacesMapView from "../components/map/AllSpacesMapView";
import { useNavigation } from "@react-navigation/native";
import LocationSearch from "../components/search/LocationSearch";
import { NotificationModal } from "../components/user/NotificationModal";
import FooterView from "../components/other/FooterView";
import { globalStyles } from "../styles/styles";
import { LoadingComponent } from '../components/LoadingComponent';
import WebPullToRefresh from '../components/other/WebPullToRefresh';
import FilterOptions from '../components/search/FilterOptions';
import FilterModal from '../components/search/FilterModal';
import { listPropertiesByInternalRatingQuery } from '../api/property';
import SwipeableView from '../components/other/SwipeableView';
import { colors } from '../styles/colors';
import Flex from '../components/CustomElements/Flex';
const { cloudfrontURLStaticImages } = getEnvVars();

const HomeScreenBody = ({ loading, setLoading, refresh = true, setRefresh = null }) => {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const mobilePlatform = Platform.OS !== 'web';
  const lakesideLatLng = { lat: 34.1425438, lng: -109.9603843 };
  const { stayType, mobileView, notifyOfNationwide } = useSelector(state => state.currentUser);
  const [coordinates, setCoordinates] = useState({});
  const [properties, setProperties] = useState([]);
  const [showMap, setShowMap] = useState(mobilePlatform);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(notifyOfNationwide);
  const [newLocationSearched, setnewLocationSearched] = useState('');
  const [searchInput, setSearchInput] = useState(false);
  const [loadingDisplay, setLoadingDisplay] = useState(true);
  const smallerScreen = width < 360;

  const listProperties = async () => {
    setLoadingDisplay(true)
    setSelectedPlace({})
    let filterData = {
      active: { eq: true }
    };
    if (!!coordinates && !!coordinates.lat) {
      const { lat, lng } = coordinates;
      const latRange = lat < 0 ? [lat + 1, lat - 1] : [lat - 1, lat + 1];
      const lngRange = lng < 0 ? [lng + 1, lng - 1] : [lng - 1, lng + 1];
      filterData['coordinates_lat'] = { between: latRange };
      filterData['coordinates_lng'] = { between: lngRange };
    }
    if (stayType === 'vacation') {
      filterData['type_shortterm'] = { eq: true };
    } else if (stayType === 'monthly') {
      filterData['type_longterm'] = { eq: true };
    } else if (stayType === 'type_yearly') {
      filterData['type_yearly'] = { eq: true };
    } else if (stayType === 'storage') {
      filterData['type_storage'] = { eq: true };
    }

    const propertiesArr = await listPropertiesByInternalRatingQuery({ filterData });
    if (propertiesArr) {
      setProperties(propertiesArr);
    } else {
      //   if (!!coordinates && !!coordinates.lat) {
      //     const { lat, lng } = coordinates;
      //     const latRange = lat < 0 ? [lat - 1, lat + 1] : [lat - 1, lat + 1];
      //     const lngRange = lng < 0 ? [lng + 1, lng - 1] : [lng + 1, lng - 1];
      //     filterData['coordinates_lat'] = { between: latRange };
      //     filterData['coordinates_lng'] = { between: lngRange };
      //     await API.graphql({
      //       query: listPropertiesQuery,
      //       variables: {
      //         filter: filterData
      //       },
      //       authMode: "API_KEY",
      //     })
      //   }
    }
    setLoading(false);
    if (!mobilePlatform) {
      setRefresh(false);
      setTimeout(() => setLoadingDisplay(false), "500")
    } else {
      setLoadingDisplay(false)
    }
  };

  const newCenter = (v) => {
    setCoordinates(v);
  };

  const clearLocation = () => {
    dispatch(setSpaceStayType({ stayType: undefined }));
    setSelectedPlace({})
    setSearchInput(false)
    setCoordinates(lakesideLatLng)
    listProperties(lakesideLatLng)
  };

  const goToPropertyPage = (property) => {
    navigation.navigate("space", {
      propertyID: property.id
    })
  };

  const setStayType = (v) => {
    dispatch(setSpaceStayType({ stayType: v }));
  };

  const closeNotification = () => {
    setNotificationModalOpen(false)
    dispatch(setNotificationViewed({ notifyOfNationwide: false }));
  };

  const goToHostSplash = () => {
    closeNotification();
    navigation.navigate("host")
  }

  useEffect(() => {
    listProperties();
  }, [stayType, coordinates, refresh]);

  return (
    <>
      {loading ? (<View style={{ marginTop: 200 }}><LoadingComponent /></View>) : (
        <>
          {mobilePlatform ? (
            <GestureHandlerRootView style={{ flex: 1, backgroundColor: colors.white }}>
              <View style={[globalStyles.center, styles.backgroundblock, { paddingTop:10, marginBottom: 0, flexWrap: "wrap", position: "absolute", top: 0, width: width, }]}>
                <LocationSearch mobileView={mobileView} searchInput={searchInput} setSearchInput={() => setSearchInput(!searchInput)} clearLocation={() => clearLocation()} setCoordinates={(v) => setCoordinates(v)} setnewLocationSearched={(v) => setnewLocationSearched(v)} />
                {properties.length ? (
                  <View style={[{ width: 60, marginLeft: 8, marginTop: 4 }]}>
                    <Badge onPress={() => setFilterModalOpen(true)} margin={0} variation='white' padding={3} height={35} opacity={1}>
                      <Ionicons name="options-outline" size={27} color="black" />
                    </Badge>
                  </View>
                ) : (<></>)}
              </View>
              <AllSpacesMapView
                coordinates={coordinates}
                spaces={properties}
                selectedPlace={selectedPlace}
                setSelectedPlace={(v) => setSelectedPlace(v)}
                updateSpacesShowing={(v) => newCenter(v)}
                goToSpacePage={(v) => goToPropertyPage(v)}
                newLocationSearched={newLocationSearched}
              />
              <SwipeableView>
                <View style={[globalStyles.center, {marginBottom: -20, marginTop: 0 }]}>
                  <ScrollView>
                    {!!properties.length ? (
                      <PropertiesGrid properties={properties} mobileView={mobileView} />
                    ) : (
                      <View style={[globalStyles.centerColumn, { marginBottom: 250 }]}>
                        <Text style={{ marginVertical: 25, marginHorizontal: 10, fontSize: 17, textAlign: "center" }}>Oh no! No spaces here yet, but more are popping up everyday.</Text>
                        <Pressable style={{ width: 150, height: 35, margin: 3 }}>
                          <Badge margin={0} variation={"info"} onPress={() => clearLocation()}>
                            <Text style={styles.fontSize}>clear search</Text>
                          </Badge>
                        </Pressable>
                      </View>
                    )}
                    <FooterView />
                  </ScrollView>
                </View>
              </SwipeableView>
            </GestureHandlerRootView>
          ) : (        
          <ScrollView>
            <View>
              <ImageBackground
                source={{ uri: `${cloudfrontURLStaticImages}splashScreen2.jpg` }}
                resizeMode="cover"
                style={[styles.image, { height: mobilePlatform ? 110 : null }]}
              >
                <View style={[mobileView ? globalStyles.flexStartColumn : globalStyles.centerColumn, { marginTop: 10 }]}>
                  <Image
                    style={{ width: smallerScreen ? width / 1.05 : mobileView ? width / 1.2 : 550, height: (smallerScreen ? (width / 1.05) * .085 : mobileView ? (width / 1.2) * .085 : 550 * .085), }}
                    source={{ uri: `${cloudfrontURLStaticImages}banner0.png` }}
                  />
                </View>
                {!mobileView ? (
                  <View style={[mobileView ? globalStyles.flexEnd : globalStyles.centerColumn]}>
                    <Image
                      style={{ width: mobileView ? width / 1.15 : 630, height: (mobileView ? width / 1.15 * .085 : 630 * .085), }}
                      source={{ uri: `${cloudfrontURLStaticImages}renterhost0.png` }}
                    />
                  </View>
                ) : (<></>)}
                <View style={[globalStyles.flexEndColumn, { height: mobilePlatform ? 75 : null }]}>
                  <Image
                    style={{ width: smallerScreen ? width / 1.3 : mobileView ? width / 2 : 350, height: (smallerScreen ? (width / 1.3) * .213 : mobileView ? (width / 2) * .213 : 350 * .213), }}
                    source={{ uri: `${cloudfrontURLStaticImages}steps0.png` }}
                  />
                </View>
              </ImageBackground>
            </View>
            <View style={[globalStyles.centerColumn, { marginTop: 20, flexWrap: "wrap" }]}>
              <View style={[globalStyles.flexStart, { marginBottom: mobileView ? 5 : 10, marginTop: mobileView ? 5 : 0, flexWrap: "wrap" }]}>
                {!loading && <LocationSearch mobileView={mobileView} searchInput={searchInput} setSearchInput={() => setSearchInput(!searchInput)} clearLocation={() => clearLocation()} setCoordinates={(v) => setCoordinates(v)} setnewLocationSearched={(v) => setnewLocationSearched(v)} />}
                {mobileView && properties.length ? (
                  <View style={[{ width: 60, marginLeft: 8, marginTop: 4 }]}>
                    <Badge onPress={() => setFilterModalOpen(true)} margin={0} variation='white' padding={3} height={35}>
                      <Ionicons name="options-outline" size={27} color="black" />
                    </Badge>
                  </View>
                ) : (<></>)}
              </View>
              {loadingDisplay && <LoadingComponent marginVertical={5} />}
              {(!!properties.length) && !mobileView ? (
                <FilterOptions
                  loading={loading}
                  showMap={showMap}
                  setShowMap={() => setShowMap(!showMap)}
                  properties={properties}
                  stayType={stayType}
                  setStayType={(v) => setStayType(v)}
                  mobileView={mobileView}
                  width={width}
                />
              ) : (<></>)}
            </View>
            {showMap &&
              <Flex
                alignContent="center"
                textAlign="center"
                justifyContent="center"
                alignItems="center"
                wrap="wrap"
              >
                <AllSpacesMapView
                  coordinates={coordinates}
                  spaces={properties}
                  selectedPlace={selectedPlace}
                  setSelectedPlace={(v) => setSelectedPlace(v)}
                  updateSpacesShowing={(v) => newCenter(v)}
                  width={width}
                  height={height}
                  goToSpacePage={(v) => goToPropertyPage(v)}
                  newLocationSearched={newLocationSearched}
                />
              </Flex>
            }
            {!loading && (
              <Flex
                alignContent="center"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                alignSelf="center"
                marginBottom={20}
                marginTop={smallerScreen ? -15 : 0}
              >
                {!!properties.length ? (
                  <PropertiesGrid properties={properties} mobileView={mobileView} />
                ) : (
                  <View style={[globalStyles.centerColumn, { width: width / 1.1 }]}>
                    <Text style={{ marginVertical: 25, fontSize: 17, textAlign: "center" }}>Oh no! No spaces here yet, but more are popping up everyday.</Text>
                    <Pressable style={{ width: 150, height: 35, margin: 3 }}>
                      <Badge margin={0} variation={"info"} onPress={() => clearLocation()}>
                        <Text style={styles.fontSize}>clear search</Text>
                      </Badge>
                    </Pressable>
                  </View>
                )}
              </Flex>
            )}
            <NotificationModal
              modalVisible={notificationModalOpen}
              setModalVisible={() => closeNotification()}
              mobileView={mobileView}
              width={width}
              goToHostSplash={() => goToHostSplash()}
            />
            <FooterView />
          </ScrollView>
          )}
        </>
      )}
      <FilterModal
        modalVisible={filterModalOpen}
        setModalVisible={() => setFilterModalOpen(!filterModalOpen)}
        setStayType={(v) => setStayType(v)}
        listProperties={() => listProperties()}
        clearLocation={() => clearLocation()}
        stayType={stayType}
        loading={loading}
        properties={properties}
        showMap={showMap}
        setShowMap={(v) => setShowMap(v)}
        coordinates={coordinates}
        spaces={properties}
        selectedPlace={selectedPlace}
        setSelectedPlace={(v) => setSelectedPlace(v)}
        updateSpacesShowing={(v) => newCenter(v)}
        width={width}
        height={height}
        goToSpacePage={(v) => goToPropertyPage(v)}
        newLocationSearched={newLocationSearched}
        newCenter={(v) => newCenter(v)}
      />
      <NotificationModal
        modalVisible={notificationModalOpen}
        setModalVisible={() => closeNotification()}
        mobileView={mobileView}
        width={width}
        goToHostSplash={() => goToHostSplash()}
      />
    </>
  )
}

function HomeScreen() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  return Platform.OS === 'web' ? (
    <View style={{ flex: 1, alignItems: 'center' }}>
      <WebPullToRefresh setRefresh={(v) => setRefresh(v)}>
        <HomeScreenBody loading={loading} setLoading={(v) => setLoading(v)} refresh={refresh} setRefresh={(v) => setRefresh(v)} />
      </WebPullToRefresh>
    </View>
  ) : (<HomeScreenBody loading={loading} setLoading={(v) => setLoading(v)} />)
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    flex: 1,
  },
  backgroundblock: {
    backgroundColor: colors.white,
    height: 80,
    top: 0,
    zIndex: 1
  },
  fontSize: {
    fontSize: 15,
    paddingBottom: 2
  }
});

export default HomeScreen;
