import { useWindowDimensions, Text, View } from "react-native";
import Header from "../CustomElements/Header"
import Card from "../CustomElements/Card"
import { colors } from "../../styles/colors";
import Badge from "../CustomElements/Badge";
import { globalStyles } from "../../styles/styles";

const InputCard = ({ headerText, required, children, subtext }) => {
    const { width } = useWindowDimensions();

    return (
        <Card
            width={width / 1.1}
            padding={20}
            marginBottom={15}
            alignItems="flex-start"
        >
            <View style={[globalStyles.flexStartColumn, { marginBottom: 20 }]}>
                <Header textAlign={"left"} padding={0} level={1.1} color={colors.amplifyNeutral80} text={headerText} />
                <Badge variation={required ? "warning" : "info"} width={150}><Text style={{ fontWeight: "500", fontSize: 17, color: colors.amplifyNeutral90 }}>{required ? "required" : "optional"}</Text></Badge>
            </View>
            <Text style={{ fontSize: 15, marginBottom: 15, fontStyle: "italic" }}>{subtext}</Text>
            <View style={[globalStyles.centerColumn, { width: "100%" }]}>
                {children}
            </View>

        </Card>
    )
}

export default InputCard;


