import React, { useState } from 'react';
import {
    Dimensions,
    StyleSheet,
    Pressable
} from 'react-native';

const CustomButtonUnderlined = ({
    children,
    onPress,
    disabled = false,
    width = Dimensions.get('window').width - 100,
    height = 40,
    padding = 0,
    marginBottom = 1,
    marginRight = 1,
    marginLeft = 1,
    marginTop = 1,
    textAlign = "center",
    alignItems = "center"
}) => {
    const [onHover, setOnHover] = useState(false)

    return (
        <Pressable
            onPress={() => onPress()}
            onHoverIn={() => setOnHover(true)}
            onHoverOut={() => setOnHover(false)}
            disabled={disabled}
            style={[styles.button, {
                marginLeft: marginLeft,
                marginRight: marginRight,
                marginBottom: marginBottom,
                marginTop: marginTop,
                padding: padding,
                textAlign: textAlign,
                alignItems: alignItems,
                width: width,
                height: height,
            }]}
        >
            {/* {(onHover && !mobileView) ? (
                <View style={{
                    backgroundColor: "#000",
                    opacity: .1,
                    borderRadius: 8
                }}>
                    {children}
                </View>
            ) : (
                <> */}
                    {children}
                {/* </>
            // )} */}
        </Pressable>
    )

}

const styles = StyleSheet.create({
    button: {
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
    },
})
export default CustomButtonUnderlined;