import React, { useContext, useEffect, useState } from "react";
import { ImageBackground, StyleSheet, Text, View, Pressable, useWindowDimensions } from "react-native";
import { useNavigation } from '@react-navigation/native';
import Flex from "../../components/CustomElements/Flex"
import { useDispatch, useSelector } from "react-redux";
import HostOnboarding from "../../components/host/HostOnboarding";
import FixedHeightCard from "../../components/CustomElements/FixedHeightCard";
import { colors } from "../../styles/colors";
import { Ionicons } from "@expo/vector-icons";
import Card from "../../components/CustomElements/Card";
import { BookingsContext } from "../../utils/BookingsContextProvider";
import { setAdditionalSpaceDetails, setNewPropertyBoolean } from "../../redux/actions/HostOnboardingActions";
import BackButton from "../../components/other/BackButton";
import getEnvVars from "../../../environment";
import CustomButton from "../../components/CustomElements/CustomButton";
import { CustomRadioButton } from "../../components/CustomElements/CustomRadioButton";
import { globalStyles } from "../../styles/styles";

const NewHostView = ({ height, width, mobileView }) => {
  const { cloudfrontURLStaticImages } = getEnvVars();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const learnMore = () => {
    navigation.navigate("host")
  }

  const moveForward = () => {
    dispatch(setNewPropertyBoolean({ newProperty: true }))
    navigation.navigate('SingleOrMultiple')
  }

  return (
    <ImageBackground
      source={{ uri: `${cloudfrontURLStaticImages}splashScreen2.jpg` }}
      resizeMode="cover"
      style={styles.image}
    >
      <Flex direction="column" alignItems="center">
        <Flex direction="column">
          <View style={[styles.title, { paddingLeft: 20, width: width, marginTop: mobileView ? 10 : 0 }]}>
            <Text style={{ fontSize: mobileView ? 17 : 30, lineHeight: mobileView ? 35 : 120, color: "white" }}>
              Host your space. Easy as 1, 2, 3.
            </Text>
            <View style={globalStyles.center}>

            <Text style={{ fontSize: mobileView ? 15 : 19, color: "white", textAlign: "center", width: width/1.5 }}>
              If you'd like help setting up your space or taking photos, dont hesitate to contact us! We will help you set up at no cost.
            </Text>
            </View>
          </View>
        </Flex>
        <View style={globalStyles.center}>
          <CustomButton
            width={170}
            height={40}
            backgroundColor={colors.orange}
            marginTop={20}
            border={colors.amplifyOrange80}
            onPress={() => moveForward()}
          >
            <Text style={[styles.buttonText]}>Get Started</Text>
          </CustomButton>
        </View>
        <Flex direction="row" justifyContent={"center"} alignItems="center" marginTop={height / 4} wrap="wrap">
          <Text style={{ fontSize: !!mobileView ? 17 : 20, color: "white", marginLeft: !mobileView ? 12 : 0 }}>
            Still have questions?
          </Text>
          <Pressable
            onPress={() => learnMore()}
            style={[styles.learnMoreButton, { height: mobileView ? 40 : 45, width: mobileView ? 120 : 150 }]}
          >
            <Text style={[styles.buttonText]}>Learn more</Text>
          </Pressable>
        </Flex>
      </Flex>
    </ImageBackground>
  )
}

const EstablishedHostView = ({ width, mobileView }) => {
  const dispatch = useDispatch();
  const { myHosting } = useContext(BookingsContext);
  const { mySpaces } = myHosting;
  const [newPropertySelected, setNewPropertySelected] = useState(false);
  const [value, setValue] = useState(null);

  useEffect(() => {
    !!mySpaces.length && setValue(mySpaces[0])
  }, [mySpaces.length]);

  const setAdditionalSpaces = () => {
    if (!newPropertySelected) {
      let existingSpaceTitles = [];
      const {
        id,
        images,
        type_shortterm,
        type_longterm,
        type_storage,
        type_yearly,
        spaces,
        title,
        address,
        description,
        outdoor_fires,
        price_longterm,
        price_shortterm,
        price_storage,
        price_yearly
      } = value;
      const {
        check_in_time,
        check_out_time,
        space_rules,
        check_in_instructions,
        quiet_hours_start,
        quiet_hours_end,
        full_refund_date,
        half_refund_date,
        distance_electric_hookup,
        distance_sewage_hookup,
        distance_water_hookup,
        things_to_do,
        instant_book,
        length,
        width,
        electric_hookup,
        water_hookup,
        sewage_hookup,
        wifi_available,
        wifi_network_name,
        wifi_password,
        shower_available,
        restroom_available,
        food_nearby,
        restaurant_nearby,
        water_accessible,
        easy_entry,
        laundry_available,
        pool_available,
        hottub_available,
        outdoor_area_available,
        indoor_area_available,
        trash_available,
        pets_allowed,
        smoking_allowed,
        adults_only
      } = spaces.items[0];
      spaces?.items.forEach((v) => {
        existingSpaceTitles.push(v.title)
      })
      const existingSpacesDetails = {
        additionalSpacesBoolean: true,
        newProperty: false,
        propertyID: id,
        instant_book: instant_book,
        imageIDs: images,
        short: type_shortterm,
        long: type_longterm,
        yearly: type_yearly,
        storage: type_storage,
        check_in_time: check_in_time,
        check_out_time: check_out_time,
        space_rules: space_rules,
        checkInInstructions: check_in_instructions,
        quiet_hours_start: quiet_hours_start,
        quiet_hours_end: quiet_hours_end,
        full_refund_date: full_refund_date,
        half_refund_date: half_refund_date,
        distance_electric_hookup: distance_electric_hookup,
        distance_water_hookup: distance_water_hookup,
        distance_sewage_hookup: distance_sewage_hookup,
        cityName: address.cityName,
        stateName: address.stateName,
        countryName: address.countryName,
        canadianCivicAddress: address.canadianCivicAddress,
        coordinates: {
          lat: address.coordinates_lat,
          lng: address.coordinates_lng
        },
        title: title,
        description: description,
        thingsInArea: things_to_do,
        length: length,
        width: width,
        electric_hookup: electric_hookup,
        water_hookup: water_hookup,
        sewage_hookup: sewage_hookup,
        wifi_available: wifi_available,
        wifi_network_name: wifi_network_name,
        wifi_password: wifi_password,
        shower_available: shower_available,
        restroom_available: restroom_available,
        food_nearby: food_nearby,
        restaurant_nearby: restaurant_nearby,
        water_accessible: water_accessible,
        easy_entry: easy_entry,
        laundry_available: laundry_available,
        pool_available: pool_available,
        hottub_available: hottub_available,
        outdoor_area_available: outdoor_area_available,
        indoor_area_available: indoor_area_available,
        trash_available: trash_available,
        pets_allowed: pets_allowed,
        smoking_allowed: smoking_allowed,
        adults_only: adults_only,
        outdoor_fires: outdoor_fires,
        price_storage: price_storage,
        price_shortterm: price_shortterm,
        price_longterm: price_longterm,
        price_yearly: price_yearly,
        existingSpaceTitles: existingSpaceTitles,
        existingSpaceID: id,
        existingSpaceTitleToUpdate: ''
      }
      dispatch(setAdditionalSpaceDetails(existingSpacesDetails));
    } else {
      dispatch(setNewPropertyBoolean({
        newProperty: true,
        additionalSpacesBoolean: false
      }))
    }
  };

  return (
    <HostOnboarding
      heading="Let's rent out another space"
      navigateF={"SingleOrMultiple"}
      nextButtonText="Get Started"
      onClickNext={() => setAdditionalSpaces()}
      showBackButton={false}
    >
      <Text style={styles.firstText}>I would like to...</Text>
      <View style={{ flexDirection: "column" }}>
        <CustomRadioButton
          text="Rent out a New Property"
          value={newPropertySelected}
          updateValue={() => setNewPropertySelected(true)}
        />
        <CustomRadioButton
          text="Add another Space to my Current Property"
          value={!newPropertySelected}
          updateValue={() => setNewPropertySelected(false)}
        />
      </View>
      {!newPropertySelected && (
        <View style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }}>
          <Text style={[styles.firstText]}>Add Space to this Property:</Text>
          {mySpaces.map((v, i) => (
            <Pressable key={i} onPress={() => setValue(v)}>
              <Flex width={mobileView ? width / 1.15 : 400} marginTop={5} justifyContent="flex-start" alignContent="center" alignItems="center">
                <Ionicons name={value?.id === v.id ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={colors.amplifyNeutral90} />
                <Text style={{ marginLeft: 10, fontSize: 17 }}>{v.title}</Text>
              </Flex>
            </Pressable>
          ))}
        </View>
      )}
      <View style={{ marginBottom: 45 }} />
    </HostOnboarding>
  )
};

function HostStartScreen() {
  const { width, height } = useWindowDimensions();
  const { imAHost, mobileView } = useSelector(state => state.currentUser);

  return (
    <>
      <BackButton goBack={false} />
      {imAHost ? <EstablishedHostView learnMore={() => learnMore()} width={width} height={height} mobileView={mobileView} /> : <NewHostView learnMore={() => learnMore()} width={width} height={height} mobileView={mobileView} />}
    </>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  darkContainer: {
    flex: 1,
    backgroundColor: 'grey'
  },
  image: {
    flex: 1,
    justifyContent: "center"
  },
  title: {
    paddingLeft: 4,
    paddingTop: 6,
    paddingBottom: 6,
    backgroundColor: "#000000c0"
  },
  text: {
    textAlign: "center",
    padding: 10
  },
  firstText: {
    fontSize: 17,
    fontStyle: "italic",
    textAlign: "center",
    padding: 10,
    marginTop: 15
  },
  buttonText: {
    color: "#fff",
    fontSize: 17,
    fontWeight: "500"
  },
  button: {
    alignItems: 'center',
    backgroundColor: colors.orange,
    borderRadius: 10,
    justifyContent: 'center',
    alignSelf: 'center',
    height: 45,
    marginTop: 40
  },
  learnMoreButton: {
    backgroundColor: '#000000c0',
    borderRadius: 10,
    marginLeft: 20,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  }
});

export default HostStartScreen;