import React, { useState, useEffect } from "react";
import {
  Pressable,
  StyleSheet,
  useWindowDimensions,
  Text as ReactText,
  Text,
  View,
} from "react-native";
import { colors } from "../../styles/colors";
import Header from "../CustomElements/Header"
import Flex from "../CustomElements/Flex"
import Card from "../CustomElements/Card"
import { useNavigation } from "@react-navigation/native";
import {
  FontAwesome,
  Entypo,
  Ionicons,
  MaterialIcons,
  AntDesign,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import SetAvailableAmenities from "./SetAvailableAmenities";
import SpaceTypes from "./SpaceTypes";
import SelectPhotos from "./SelectPhotos";
import SetPrice from "./SetPrice";
import SetDimensions from "./SetDimensions";
import SetDistances from "./SetDistances";
import SetThingsToKnow from "./SetThingsToKnow";
import ThingsInAreaInput from "../host/ThingsInAreaInput";
import CustomButton from "../CustomElements/CustomButton";
import CustomTextInput from "../CustomElements/CustomTextInput";
import { uploadSaveImage } from "../../utils/uploadSaveImages";
import SelectEditSpaceOrProperty from "../host/SelectEditSpaceOrProperty";
import { notifyHookhub } from "../../lambdaFunctions/notifyHookhub";
import { updateSpaceMutation } from "../../api/space";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import { LoadingComponent } from "../LoadingComponent";
import { updatePropertyMutation } from "../../api/property";
import { globalStyles } from "../../styles/styles";

const EditDetails = ({ space, multipleSpacesBoolean, closeAccordian, finishUpdate, setError, mobileView, screenWidth, updatePropertyBoolean, property }) => {
  const {
    width,
    length,
    distance_electric_hookup,
    distance_sewage_hookup,
    distance_water_hookup,
    electric_hookup,
    water_hookup,
    sewage_hookup,
    wifi_available,
    shower_available,
    restroom_available,
    food_nearby,
    water_accessible,
    restaurant_nearby,
    easy_entry,
    laundry_available,
    pool_available,
    hottub_available,
    outdoor_area_available,
    indoor_area_available,
    trash_available,
    pets_allowed,
    smoking_allowed,
    adults_only,
    outdoor_fires,
    things_to_do,
    check_in_time,
    check_out_time,
    space_rules,
    instant_book,
    check_in_instructions,
    quiet_hours_start,
    quiet_hours_end,
    full_refund_date,
    half_refund_date,
    ownerID
  } = space;
  const {
    type_shortterm,
    type_longterm,
    type_storage,
    type_yearly,
    price_storage,
    price_longterm,
    price_yearly,
    price_shortterm,
  } = property;
  const propertyTitle = property.title;
  const spaceTitle = space.title;
  const propertyDescription = property.description;
  const spaceDescription = space.description || "";
  const allSpaces = property.spaces.items;
  const spaceIndex = allSpaces.findIndex((v) => v.id === space.id)
  const [loading, setLoading] = useState(false);
  const [electrichookup, setelectrichookup] = useState(electric_hookup);
  const [waterhookup, setwaterhookup] = useState(water_hookup);
  const [sewagehookup, setsewagehookup] = useState(sewage_hookup);
  const [wifiavailable, setwifiavailable] = useState(wifi_available);
  const [showeravailable, setshoweravailable] = useState(shower_available);
  const [easyentry, seteasyentry] = useState(easy_entry);
  const [laundry, setlaundry] = useState(laundry_available);
  const [pool, setpool] = useState(pool_available);
  const [hottub, sethottub] = useState(hottub_available);
  const [outdoorarea, setoutdoorarea] = useState(outdoor_area_available);
  const [indoorarea, setindoorarea] = useState(indoor_area_available);
  const [trash, settrash] = useState(trash_available);
  const [pets, setpets] = useState(pets_allowed);
  const [smoking, setsmoking] = useState(smoking_allowed);
  const [adultsOnly, setadultsOnly] = useState(adults_only);
  const [fires, setfires] = useState(outdoor_fires);
  const [editSpaceTitle, setEditSpaceTitle] = useState(spaceTitle);
  const [editPropertyTitle, setEditPropertyTitle] = useState(propertyTitle);
  const [editPropertyDescription, setEditPropertyDescription] = useState(propertyDescription);
  const [editSpaceDescription, setEditSpaceDescription] = useState(spaceDescription);
  const [shortterm, setshortterm] = useState(type_shortterm || false);
  const [longterm, setlongterm] = useState(type_longterm || false);
  const [storage, setstorage] = useState(type_storage || false);
  const [yearly, setyearly] = useState(type_yearly || false);
  const [amountStorage, setamountStorage] = useState(price_storage);
  const [amountLongTerm, setamountLongTerm] = useState(price_longterm);
  const [amountYearly, setamountYearly] = useState(price_yearly);
  const [amountShortTerm, setamountShortTerm] = useState(price_shortterm);
  const [editWidth, seteditWidth] = useState(width);
  const [editLength, seteditLength] = useState(length);
  const [distanceelectric, setdistanceelectric] = useState(distance_electric_hookup || 20);
  const [distancewater, setdistancewater] = useState(distance_water_hookup || 20);
  const [distancesewage, setdistancesewage] = useState(distance_sewage_hookup || 10);
  const [checkInTime, setcheckInTime] = useState((check_in_time || ''));
  const [checkOutTime, setcheckOutTime] = useState((check_out_time || ''));
  const [spaceRules, setspaceRules] = useState((space_rules || ''));
  const [instantBook, setInstantBook] = useState((instant_book || false));
  const [checkInInstructions, setcheckInInstructions] = useState((check_in_instructions || ''));
  const [quietStart, setquietStart] = useState((quiet_hours_start || ''));
  const [quietEnd, setquietEnd] = useState((quiet_hours_end || ''));
  const [fullRefundDate, setfullRefundDate] = useState((full_refund_date || 4));
  const [halfRefundDate, sethalfRefundDate] = useState((half_refund_date || 2));
  const [disabled, setDisabled] = useState(false);
  const [checkInDefined, setcheckInDefined] = useState(!!check_in_time || false);
  const [quietTimeDefined, setquietTimeDefined] = useState(!!quiet_hours_start || false);
  const [thingsInArea, setThingsInArea] = useState(things_to_do || '');
  const [text2Length, settext2Length] = useState(0);
  const [error2, setError2] = useState(false);
  const [restroomavailable, setrestroomavailable] = useState((restroom_available));
  const [foodnearby, setfoodnearby] = useState((food_nearby));
  const [restaurantnearby, setrestaurantnearby] = useState((restaurant_nearby));
  const [wateraccessible, setwateraccessible] = useState((water_accessible));

  async function updateProperty() {
    const data = {
      id: property.id,
      title: editPropertyTitle,
      instant_book: instantBook,
      type_storage: storage,
      type_longterm: longterm,
      type_shortterm: shortterm,
      type_yearly: yearly,
      price_storage: amountStorage,
      price_shortterm: amountShortTerm,
      price_longterm: amountLongTerm,
      price_yearly: amountYearly,
      description: editPropertyDescription
    };

    const updatedProperty = await updatePropertyMutation({ data });
    if (updatedProperty) {
      finishUpdate();
      setLoading(false);
      closeAccordian();
    } else {
      notifyHookhubUpdateERROR()
    }
  };

  async function updateSpace({ data }) {
    try {
      await updateSpaceMutation({ data });
      return true;
    } catch {
      notifyHookhubUpdateERROR(data)
      return false;
    }
  };

  async function handleUpdates() {
    setLoading(true);
    if (updatePropertyBoolean) {
      for (const key of Object.keys(allSpaces)) {
        const data = {
          id: allSpaces[key].id,
          price_storage: amountStorage,
          price_shortterm: amountShortTerm,
          price_longterm: amountLongTerm,
          price_yearly: amountYearly,
          electric_hookup: !!electrichookup,
          water_hookup: !!waterhookup,
          sewage_hookup: !!sewagehookup,
          wifi_available: wifiavailable,
          shower_available: showeravailable,
          restroom_available: restroom_available,
          food_nearby: food_nearby,
          water_accessible: water_accessible,
          restaurant_nearby: restaurant_nearby,
          easy_entry: easyentry,
          distance_electric_hookup: distanceelectric,
          distance_water_hookup: distancewater,
          distance_sewage_hookup: distancesewage,
          check_in_time: checkInTime,
          check_out_time: checkOutTime,
          space_rules: spaceRules,
          instant_book: instantBook,
          check_in_instructions: checkInInstructions,
          quiet_hours_start: quietStart,
          quiet_hours_end: quietEnd,
          full_refund_date: fullRefundDate,
          half_refund_date: halfRefundDate,
          laundry_available: laundry,
          pool_available: pool,
          hottub_available: hottub,
          outdoor_area_available: outdoorarea,
          indoor_area_available: indoorarea,
          trash_available: trash,
          pets_allowed: pets,
          smoking_allowed: smoking,
          adults_only: adultsOnly,
          outdoor_fires: fires,
          things_to_do: thingsInArea,
        };
        let spaceUpdatedSuccess = await updateSpace({ data });
        spaceUpdatedSuccess && (Number(key) + 1 === allSpaces.length) && updateProperty();
        if (!spaceUpdatedSuccess) {
          break;
        }
      }
    } else {
      const data = {
        id: id,
        title: editSpaceTitle,
        description: editSpaceDescription,
        price_storage: amountStorage,
        price_shortterm: amountShortTerm,
        price_longterm: amountLongTerm,
        price_yearly: amountYearly,
        electric_hookup: !!electrichookup,
        water_hookup: !!waterhookup,
        sewage_hookup: !!sewagehookup,
        wifi_available: wifiavailable,
        shower_available: showeravailable,
        restroom_available: restroom_available,
        food_nearby: food_nearby,
        water_accessible: water_accessible,
        restaurant_nearby: restaurant_nearby,
        easy_entry: easyentry,
        distance_electric_hookup: distanceelectric,
        distance_water_hookup: distancewater,
        distance_sewage_hookup: distancesewage,
        check_in_time: checkInTime,
        check_out_time: checkOutTime,
        space_rules: spaceRules,
        instant_book: instantBook,
        check_in_instructions: checkInInstructions,
        quiet_hours_start: quietStart,
        quiet_hours_end: quietEnd,
        full_refund_date: fullRefundDate,
        half_refund_date: halfRefundDate,
        laundry_available: laundry,
        pool_available: pool,
        hottub_available: hottub,
        outdoor_area_available: outdoorarea,
        indoor_area_available: indoorarea,
        trash_available: trash,
        pets_allowed: pets,
        smoking_allowed: smoking,
        adults_only: adultsOnly,
        outdoor_fires: fires,
        things_to_do: thingsInArea,
      };
      let spaceUpdatedSuccess = await updateSpace({ data });
      if (spaceUpdatedSuccess) {
        finishUpdate();
        setLoading(false);
        closeAccordian();
      }
    }
  };

  const notifyHookhubUpdateERROR = (data) => {
    console.log("ERROR   >>>  ")
    setError(true);
    setLoading(false);
    let subject = `ERROR updating Property and/or Space`
    let body = `ERROR while trying to UPDATE Property/Space. DATA:>>>>    ${data}`
    notifyHookhub({ subject, body, userID: ownerID, userEmail: "not present" })
  };

  useEffect(() => {
    if (thingsInArea.length > 500) {
      setError2(true)
      setDisabled(true)
    } else {
      setError2(false)
      setDisabled(false)
    }
    settext2Length(thingsInArea.length);
  }, [thingsInArea.length]);

  return (
    <Card
      backgroundColor={colors.amplifyNeutral20}
      alignItems="center"
      textAlign="center"
      margin={!mobileView ? 4 : 0}
      width={screenWidth / 1.1}
    >

      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <CloseButton setOpenAccordian={() => closeAccordian()} />
          {updatePropertyBoolean ? (
            <>
              <Header paddingTop={6} paddingBottom={2} text="Property Title"></Header>
              <CustomTextInput
                multiline={true}
                width={screenWidth / 1.3}
                onChangeText={(e) => setEditPropertyTitle(e)}
                label="Title"
                labelHidden
                value={editPropertyTitle}
                backgroundColor="#fff"
              />
              <Header paddingTop={6} paddingBottom={2} text="Description"></Header>
              <CustomTextInput
                multiline={true}
                width={screenWidth / 1.3}
                label="Description"
                wrap="nowrap"
                height={200}
                onChangeText={(e) => setEditPropertyDescription(e)}
                value={editPropertyDescription}
                backgroundColor="#fff"
              />
              <Header paddingTop={6} paddingBottom={4} text="Amenities"></Header>
              <SetAvailableAmenities
                width={screenWidth}
                electrichookup={electrichookup}
                waterhookup={waterhookup}
                sewagehookup={sewagehookup}
                wifiavailable={wifiavailable}
                showeravailable={showeravailable}
                easyentry={easyentry}
                laundry={laundry}
                pool={pool}
                hottub={hottub}
                outdoorarea={outdoorarea}
                indoorarea={indoorarea}
                trash={trash}
                petsAllowed={pets}
                smokingAllowed={smoking}
                adultsOnly={adultsOnly}
                outdoorFires={fires}
                restroomavailable={restroomavailable}
                foodnearby={foodnearby}
                wateraccessible={wateraccessible}
                restaurantnearby={restaurantnearby}
                setlaundry={() => setlaundry(!laundry)}
                setoutdoorarea={() => setoutdoorarea(!outdoorarea)}
                setindoorarea={() => setindoorarea(!indoorarea)}
                settrash={() => settrash(!trash)}
                setpool={() => setpool(!pool)}
                sethottub={() => sethottub(!hottub)}
                setpetsAllowed={() => setpets(!pets)}
                setsmokingAllowed={() => setsmoking(!smoking)}
                setadultsOnly={() => setadultsOnly(!adultsOnly)}
                setoutdoorFires={() => setfires(!fires)}
                setelectrichookup={() => setelectrichookup(!electrichookup)}
                setwaterhookup={() => setwaterhookup(!waterhookup)}
                setsewagehookup={() => setsewagehookup(!sewagehookup)}
                setwifiavailable={() => setwifiavailable(!wifiavailable)}
                setshoweravailable={() => setshoweravailable(!showeravailable)}
                seteasyentry={() => seteasyentry(!easyentry)}
                setrestroomavailable={() => setrestroomavailable(!restroomavailable)}
                setfoodnearby={() => setfoodnearby(!foodnearby)}
                setrestaurantnearby={() => setrestaurantnearby(!restaurantnearby)}
                setwateraccessible={() => setwateraccessible(!wateraccessible)}
              />
              <Header paddingTop={8} paddingBottom={2} text="Space type"></Header>
              <SpaceTypes
                shortterm={shortterm}
                longterm={longterm}
                storage={storage}
                yearly={yearly}
                onYearlyChange={() => setyearly(!yearly)}
                onShortChange={() => setshortterm(!shortterm)}
                onLongChange={() => setlongterm(!longterm)}
                onStorageChange={() => setstorage(!storage)}
                width={screenWidth}
                mobileView={mobileView}
              />

              <Header paddingTop={6} paddingBottom={4} text="Price"></Header>
              {
                shortterm && (
                  <SetPrice
                    mobileView={mobileView}
                    val={amountShortTerm}
                    setVal={(v) => setamountShortTerm(v)}
                    title="Short Term"
                    subtext="$ for nightly stays"
                  />
                )
              }
              {
                longterm && (
                  <SetPrice
                    mobileView={mobileView}
                    val={amountLongTerm}
                    setVal={(v) => setamountLongTerm(v)}
                    title="Long Term (Monthly)"
                    subtext="$ for monthly stays (price per night)"
                  />
                )
              }
              {
                yearly && (
                  <SetPrice
                    mobileView={mobileView}
                    val={amountYearly}
                    setVal={(v) => setamountYearly(v)}
                    title="Long Term Yearly"
                    subtext="$ for yearly stays (price per night)"
                  />
                )
              }
              {
                storage && (
                  <SetPrice
                    mobileView={mobileView}
                    val={amountStorage}
                    setVal={(v) => setamountStorage(v)}
                    title="Storage"
                    subtext="$ per day price"
                  />
                )
              }

              <ThingsInAreaInput
                thingsInArea={thingsInArea}
                setThingsInArea={(v) => setThingsInArea(v)}
                text2Length={text2Length}
                error={error2}
                width={screenWidth}
                mobileView={mobileView}
              />

              <SetThingsToKnow
                checkInTime={checkInTime}
                checkOutTime={checkOutTime}
                spaceRules={spaceRules}
                instantBook={instantBook}
                checkInInstructions={checkInInstructions}
                quietStart={quietStart}
                quietEnd={quietEnd}
                fullRefundDate={fullRefundDate}
                halfRefundDate={halfRefundDate}
                quietTimeDefined={quietTimeDefined}
                checkInDefined={checkInDefined}
                setquietTimeDefined={setquietTimeDefined}
                setcheckInDefined={setcheckInDefined}
                setquietEnd={(v) => setquietEnd(v)}
                setfullRefundDate={(v) => setfullRefundDate(v)}
                sethalfRefundDate={(v) => sethalfRefundDate(v)}
                setcheckInTime={(v) => setcheckInTime(v)}
                setcheckOutTime={(v) => setcheckOutTime(v)}
                setspaceRules={(v) => setspaceRules(v)}
                setInstantBook={() => setInstantBook(!instantBook)}
                setcheckInInstructions={(v) => setcheckInInstructions(v)}
                setquietStart={(v) => setquietStart(v)}
                setDisabled={(v) => setDisabled(v)}
              />
              {!multipleSpacesBoolean && (
                <>
                  <Header paddingTop={6} paddingBottom={4} text="Dimensions"></Header>
                  <SetDimensions
                    width={editWidth}
                    length={editLength}
                    setlength={(v) => seteditLength(v)}
                    setwidth={(v) => seteditWidth(v)}
                  />

                  <Header paddingTop={6} paddingBottom={4}>
                    Distances
                  </Header>
                  {
                    electrichookup && (
                      <SetDistances
                        mobileView={mobileView}
                        val={distanceelectric}
                        setVal={(v) => setdistanceelectric(v)}
                        title="Distance to ELECTRIC hookup"
                        subtext="(ft)"
                        icon={
                          <MaterialIcons
                            name="electrical-services"
                            size={50}
                            color={colors.orange}
                          />
                        }
                      />
                    )
                  }
                  {
                    waterhookup && (
                      <SetDistances
                        mobileView={mobileView}
                        val={distancewater}
                        setVal={(v) => setdistancewater(v)}
                        title="Distance to WATER hookup"
                        subtext="(ft)"
                        icon={<Ionicons name="water-sharp" size={50} color={colors.orange} />}
                      />
                    )
                  }
                  {
                    sewagehookup && (
                      <SetDistances
                        mobileView={mobileView}
                        val={distancesewage}
                        setVal={(v) => setdistancesewage(v)}
                        title="Distance to SEWAGE hookup"
                        subtext="(ft)"
                        icon={
                          <MaterialCommunityIcons name="pipe" size={50} color={colors.orange} />
                        }
                      />
                    )
                  }
                </>
              )}
              <>
                <CustomButton
                  marginTop={30}
                  marginBottom={12}
                  width={150}
                  backgroundColor={disabled ? colors.amplifyNeutral60 : colors.hookhubOrangeDark}
                  color="#fff"
                  disabled={disabled}
                  border={disabled ? "#4c4e52" : colors.amplifyOrange80}
                  onPress={() => handleUpdates()}
                >
                  <ReactText style={{ fontSize: 17 }}>SAVE</ReactText>
                </CustomButton>
                <Pressable onPress={() => closeAccordian()} style={styles.backButton}>
                  <ReactText style={styles.backButtonText}>Cancel</ReactText>
                </Pressable>
              </>
            </>
          ) : (
            <>
              <Header paddingTop={6} paddingBottom={2} text="Title"></Header>
              <CustomTextInput
                multiline={true}
                width={screenWidth / 1.3}
                onChangeText={(e) => setEditSpaceTitle(e)}
                label="Title"
                labelHidden
                value={editSpaceTitle}
                backgroundColor="#fff"
              />
              <Header paddingTop={6} paddingBottom={2} text="Description"></Header>
              <CustomTextInput
                multiline={true}
                width={screenWidth / 1.3}
                label="Description"
                wrap="nowrap"
                height={200}
                onChangeText={(e) => setEditSpaceDescription(e)}
                value={editSpaceDescription}
                backgroundColor="#fff"
              />
              <Header paddingTop={6} paddingBottom={4} text="Dimensions"></Header>
              <SetDimensions
                width={editWidth}
                length={editLength}
                setlength={(v) => seteditLength(v)}
                setwidth={(v) => seteditWidth(v)}
              />

              <Header paddingTop={6} paddingBottom={4}>
                Distances
              </Header>
              {
                electrichookup && (
                  <SetDistances
                    mobileView={mobileView}
                    val={distanceelectric}
                    setVal={(v) => setdistanceelectric(v)}
                    title="Distance to ELECTRIC hookup"
                    subtext="(ft)"
                    icon={
                      <MaterialIcons
                        name="electrical-services"
                        size={50}
                        color={colors.orange}
                      />
                    }
                  />
                )
              }
              {
                waterhookup && (
                  <SetDistances
                    mobileView={mobileView}
                    val={distancewater}
                    setVal={(v) => setdistancewater(v)}
                    title="Distance to WATER hookup"
                    subtext="(ft)"
                    icon={<Ionicons name="water-sharp" size={50} color={colors.orange} />}
                  />
                )
              }
              {
                sewagehookup && (
                  <SetDistances
                    mobileView={mobileView}
                    val={distancesewage}
                    setVal={(v) => setdistancesewage(v)}
                    title="Distance to SEWAGE hookup"
                    subtext="(ft)"
                    icon={
                      <MaterialCommunityIcons name="pipe" size={50} color={colors.orange} />
                    }
                  />
                )
              }
              <CustomButton
                marginTop={30}
                marginBottom={12}
                width={150}
                backgroundColor={disabled ? colors.amplifyNeutral60 : colors.hookhubOrangeDark}
                color="#fff"
                disabled={disabled}
                border={disabled ? "#4c4e52" : colors.amplifyOrange80}
                onPress={() => handleUpdates()}
              >
                <ReactText style={{ fontSize: 17 }}>SAVE</ReactText>
              </CustomButton>
              <Pressable onPress={() => closeAccordian()} style={styles.backButton}>
                <ReactText style={styles.backButtonText}>Cancel</ReactText>
              </Pressable>
            </>
          )}
        </>
      )}
    </Card>
  );
};

const Accordian = ({ title, icon, width }) => {
  return (
    <View style={[globalStyles.spaceBetween, { width: width, alignItems: "center" }]}>
      <View>
        <AntDesign name="caretright" size={20} color={colors.amplifyNeutral80} />
      </View>
      <View style={globalStyles.center}>
        {icon}
        <Header text={title}></Header>
      </View>
    </View>
  )
};

const CloseButton = ({ setOpenAccordian }) => {
  return (
    <Flex justifyContent="flex-start" alignContent="center" alignItems="center" marginTop={6} marginLeft={15} wrap="wrap">
      <CustomButton
        width={100}
        backgroundColor={colors.amplifyNeutral60}
        onPress={() => setOpenAccordian()}
      >
        <View style={{ flexDirection: "row" }}>
          <AntDesign name="caretdown" size={20} color={colors.amplifyNeutral80} />
          <ReactText style={{ paddingLeft: 10, fontSize: 17 }}>Close</ReactText>
        </View>
      </CustomButton>
    </Flex>
  )
}

const DetailsAccordian = ({ space, multipleSpacesBoolean, finishUpdate, mobileView, updateProperty, property, setError, width }) => {
  const [openAccordian, setOpenAccordian] = useState(false);
  const [editThisSpace, setEditThisSpace] = useState({})

  useEffect(() => {
    setEditThisSpace(space)
    setOpenAccordian(false)
  }, [space, updateProperty])

  return (
    <>
      {openAccordian && editThisSpace ? (
        <EditDetails
          space={editThisSpace}
          updatePropertyBoolean={updateProperty}
          closeAccordian={() => setOpenAccordian(false)}
          finishUpdate={() => finishUpdate()}
          setError={() => setError()}
          mobileView={mobileView}
          screenWidth={width}
          property={property}
          multipleSpacesBoolean={multipleSpacesBoolean}
        />
      ) : (
        <Card
          backgroundColor={colors.amplifyNeutral20}
          border="#4c4e52"
          padding={!!mobileView ? 4 : 8}
          alignItems="center"
          textAlign="center"
          width={width / 1.1}
        >
          <Pressable onPress={() => setOpenAccordian(true)} margin={!!mobileView ? 4 : 12}>
            <Accordian width={width / 1.15} title="Edit Details and Amenities" icon={<Entypo name="edit" size={20} color="grey" />} />
          </Pressable>
        </Card>
      )}
    </>
  );
};

const UpdateAvailableTimesAccordian = ({ goToEditAvailabilityPage, width }) => {
  return (
    <Card
      backgroundColor={colors.amplifyNeutral20}
      border="#4c4e52"
      boxShadow="#737272"
      padding={8}
      alignItems="center"
      textAlign="center"
      width={width / 1.1}
    >
      <Pressable onPress={goToEditAvailabilityPage} margin={12}>
        <Accordian width={width / 1.15} title="Update Unavailable Times" icon={<FontAwesome name="calendar" size={20} color="grey" />} />
      </Pressable>
    </Card>
  )
}

const ImagesAccordian = ({ space, property, mobileView, width }) => {
  const propertyImages = property.images
  const [openAccordian, setOpenAccordian] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateimages, setUpdateImages] = useState({});
  const [previewImages, setPreviewImages] = useState({});

  useEffect(() => {
    let imagesDB = {};
    let imagesPreview = {};
    if (!!propertyImages) {
      for (let i = 0; i < propertyImages.length; i++) {
        let imageString = propertyImages[i];
        const index = propertyImages[i].slice(0, 1);
        imagesDB[index] = imageString;
        imagesPreview[index] = null;
      }
      setUpdateImages(imagesDB)
      setPreviewImages(imagesPreview)
    }
  }, []);

  const updateImagesArr = async (index, x, y, z) => {
    let uploadImages = { imageID: x, blob: z }
    await uploadSaveImage(uploadImages)
    const tempObj = { ...updateimages };
    const tempPreviewObj = { ...previewImages };
    tempObj[index] = x;
    tempPreviewObj[index] = y;
    setPreviewImages(tempPreviewObj);
    setUpdateImages(tempObj);
    setLoading(false);
  };

  const resetAndOpen = () => {
    setPreviewImages({});
    setSuccess(false);
    setLoading(false);
    setOpenAccordian(true);
  }

  async function updatePropertyImages() {
    setLoading(true);
    const saveImgs = (Object.values(updateimages));
    const data = {
      id: property.id,
      images: saveImgs
    }
    const updatedProperty = await updatePropertyMutation({ data });
    if (updatedProperty) {
      setSuccess(true);
      setLoading(false);
    } else {
      setLoading(false);
      setError(true)
    }
  };

  const cancelImageUpdate = () => {
    setPreviewImages(null);
    setOpenAccordian(false);
    setSuccess(false);
  };

  return (
    <>
      {openAccordian ? (
        <Card
          backgroundColor={colors.amplifyNeutral20}
          border="#4c4e52"
          boxShadow="#737272"
          padding={!mobileView ? 8 : 0}
          alignItems="center"
          textAlign="center"
          width={width / 1.1}
        >
          <CloseButton setOpenAccordian={() => setOpenAccordian(false)} />
          <Text style={{ fontSize: 16, margin: 20 }}>* Please note: Images can only be updated for your entire property at this time. The ability to update individual space images will be available soon.</Text>
          <Header text="Property Images" />
          {!!Object.keys(updateimages).length &&
            <SelectPhotos
              images={updateimages}
              imagePreviews={previewImages}
              loading={loading}
              setLoading={() => setLoading(!loading)}
              setImages={(i, x, y, z) => updateImagesArr(i, x, y, z)}
            />
          }
          {success ? (
            <Flex direction="column" justifyContent="center" alignContent="center" alignItems="center" marginTop={8} >
              <AntDesign name="checkcircle" size={35} color="#78b2d6" />
              <ReactText style={styles.longDescription}>please reload to see new images in your space</ReactText>
              <Pressable onPress={() => cancelImageUpdate()} style={styles.backButton} disabled={loading ? true : false}>
                <ReactText style={styles.backButtonText}>close</ReactText>
              </Pressable>
            </Flex>
          ) : (
            <>
              <CustomButton
                marginTop={30}
                marginBottom={12}
                width={150}
                backgroundColor={colors.hookhubOrangeDark}
                color="#fff"
                border={colors.amplifyOrange90}
                onPress={() => updatePropertyImages()}
              >
                <ReactText style={{ fontSize: 17 }}>SAVE</ReactText>
              </CustomButton>
              <Pressable onPress={() => cancelImageUpdate()} style={styles.backButton}>
                <ReactText style={styles.backButtonText}>Cancel</ReactText>
              </Pressable>
            </>
          )}
        </Card>
      ) : (
        <Card
          backgroundColor={colors.amplifyNeutral20}
          border="#4c4e52"
          boxShadow="#737272"
          padding={!mobileView ? 8 : 0}
          alignItems="center"
          textAlign="center"
          width={width / 1.1}
        >
          <Pressable onPress={() => resetAndOpen()} margin={12}>
            <Accordian width={width / 1.15} title="Update Photos" icon={<Entypo name="image" size={20} color="grey" />} />
          </Pressable>
        </Card>
      )}
    </>
  );
};

function EditMyProperty({
  property,
  finishUpdate,
  mobileView,
  width
}) {
  const navigation = useNavigation();
  const spaces = property?.spaces?.items;
  const [displaySpace, setDisplaySpace] = useState(spaces[0]);
  const [updateProperty, setUpdateProperty] = useState(true);
  const [error, setError] = useState(false);
  const multipleSpacesBoolean = spaces.length > 1;

  const goToEditAvailabilityPage = () => {
    navigation.navigate("hostaccount", {
      screen: "availability",
      params: {
        spaceID: displaySpace.id,
        spaceName: displaySpace.title,
        propertyName: property.title
      },
    });
  };

  return (
    <View style={globalStyles.centerColumn}>
      {error ? (
        <ErrorDisplay error={"Error updating space. Hookhub has been notified and will reach out shortly. If this is urgent, please contact Hookhub via the Help Screen."} />
      ) : (
        <>
          {multipleSpacesBoolean && (
            <SelectEditSpaceOrProperty
              spaces={spaces}
              width={width}
              mobileView={mobileView}
              displaySpace={displaySpace}
              setDisplaySpace={(v) => setDisplaySpace(v)}
              updateProperty={updateProperty}
              setUpdateProperty={(v) => setUpdateProperty(v)}
              deactiveOrDelete={false}
            />
          )}
          <DetailsAccordian
            space={displaySpace}
            property={property}
            width={width}
            updateProperty={updateProperty}
            setError={() => setError(true)}
            finishUpdate={() => finishUpdate()}
            mobileView={mobileView}
            multipleSpacesBoolean={multipleSpacesBoolean}
          />
          <UpdateAvailableTimesAccordian
            mobileView={mobileView}
            width={width}
            goToEditAvailabilityPage={() => goToEditAvailabilityPage()}
          />
          <ImagesAccordian
            space={displaySpace}
            mobileView={mobileView}
            property={property}
            width={width}
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  types: {
    color: colors.brandGrey,
    marginVertical: 10,
  },
  images: {
    borderRadius: 8,
    alignSelf: "center",
    justifyContent: "center",
    alignContent: "center"
  },
  backButtonText: {
    color: "#484a4a",
    fontSize: 17,
    marginBottom: 10,
    fontWeight: "bold",
    textDecorationLine: "underline"
  },
  backButton: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  image: {
    aspectRatio: 3 / 2,
    borderRadius: 10,
    resizeMode: "cover",
    width: "100%",
  },
  longDescription: {
    fontSize: 17,
    lineHeight: 24,
    marginVertical: 10,
  },
  newPrice: {
    fontWeight: "bold",
  },
  oldPrice: {
    color: colors.brandGrey,
    textDecorationLine: "line-through",
  },
  prices: {
    fontSize: 17,
    marginVertical: 10,
  },
  totalPrice: {
    color: colors.brandGrey,
    textDecorationLine: "underline",
  },
});

export default EditMyProperty;
