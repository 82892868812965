import React, { useState } from "react";
import { Pressable, useWindowDimensions, Image, Text } from "react-native";
import {
    AntDesign,
    FontAwesome5,
} from "@expo/vector-icons";
import { useSelector } from "react-redux";
import Flex from "./CustomElements/Flex"
import Card from "./CustomElements/Card"
import Header from "./CustomElements/Header"
import { colors } from "../styles/colors";
import { View } from "react-native";
import { globalStyles } from "../styles/styles";


const QuestionAccordian = ({ question, mobileView }) => {
    const [openAccordian, setOpenAccordian] = useState(false);
    const { width } = useWindowDimensions();

    return (
        <Card
            backgroundColor={colors.amplifyNeutral20}
            border="#4c4e52"
            padding={!!mobileView ? 2 : 8}
            alignItems="flex-start"
            marginTop={6}
            textAlign="center"
            width={!!mobileView ? width / 1.18 : width / 1.3}
        >
            <View style={globalStyles.flexStartColumn}>
                <Pressable onPress={() => setOpenAccordian(!openAccordian)} margin={12}>
                    <View style={globalStyles.center}>
                        <AntDesign marginRight={15} name={openAccordian ? "caretdown" : "caretright"} size={20} color="grey" />
                        <Header level={mobileView ? .9 : 1} text={question.q}></Header>
                    </View>
                </Pressable>
                {openAccordian && <Text style={{ fontSize: 16, margin:10, marginTop: 20 }}>{question.a}</Text>}
            </View>
        </Card >
    );
};

const InfoCard = ({ title, icon, width, mobileView, questions }) => {
    const [openAccordian, setOpenAccordian] = useState(false);

    return (
        <Card width={!!mobileView ? width / 1.1 : width / 1.2} alignItems="flex-start">
            <Pressable onPress={() => setOpenAccordian(!openAccordian)}>
                <View style={[globalStyles.flexStart, { alignItems: "center" }]}>
                    <AntDesign name={openAccordian ? "caretdown" : "caretright"} size={20} color="grey" style={{ marginRight: mobileView ? 10 : 30 }} />
                    {icon}
                    <Header
                        color="#4c4e52"
                        level={1}
                        text={title}
                        marginRight={mobileView ? 6 : 10}
                    />
                    <Text>Answers to your questions</Text>
                </View>
            </Pressable>
            {openAccordian && (
                <View style={{ marginTop: 10 }}>
                    {questions.map((v, i) => (
                        <QuestionAccordian mobileView={mobileView} question={v} key={i} />
                    ))}
                </View>
            )}
        </Card>
    );
};

function LearnMore() {
    const { width } = useWindowDimensions();
    const { mobileView } = useSelector(state => state.currentUser);
    const hostQs = [
        {
            q: "Is my space right for Hosting?",
            a: "If you have space on your property of at least 10 foot by 20 foot- you can host on hookhub! Only space is needed, but you can also offer extra amenities if you'd like to charge higher prices."
        },
        {
            q: "Is there a minimum or maximum amount of time I have to let people rent my space?",
            a: "Absolutely not, it is entirely up to you on when and how long people can rent your space. You can offer your space for short-term rentals, long-term monthly stays or even just for storage space."
        },
        {
            q: "What do I have to do as a Host?",
            a: "Its simple- just create your space in a few simple steps and set your space availability. Thats it! You will get booking requests and then be able to decide at that time if you like the booking or renter that is requesting."
        },
        {
            q: "How do I know I can trust renters?",
            a: "We review the renters and will even verify their identity if fraudulent activity is suspected. You also will have the chance to review your renters details and speak to them prior to agreeing to their reservation request."
        },
        {
            q: "Can I host my space if it is just empty land?",
            a: "Yes! Amenities (electric, water, sewage, etc) are an extra feature if you choose to list it. Otherwise, only space is needed."
        },
        {
            q: "What is the host cancellation policy?",
            a: "As a host, you can cancel a booking up to 1 week before the check-in date. If the booking is more than a month long, you can cancel the booking after check-in, as long as it is still 28 days before the check-out date. A 28 days notice will be required."
        },
        {
            q: "What are Hookhub's fees?",
            a: "To keep our platform up and provide support- Hookhub collects 10% of the total being paid by your renter as well as a Stripe transaction fee (per Stripe) of 2.9% plus $.30."
        },
    ]
    const renterQs = [
        {
            q: "How does the hookhub rental process work?",
            a: "Search your destination and find a rental space that fits your needs- with amenities and stay types (long-term, short-term and storage). Then simply create your booking request in a couple of clicks."
        },
        {
            q: "Is my payment secure?",
            a: "Absolutely. We partner with Stripe to provide a secure payment and payout system."
        },
        {
            q: "What can I use Hookhub for?",
            a: "Hookhub is your online platform to rent space to park your RV, trailer, boat, etc. for long term living, short term vacation rental space or even just storage. Each host will list any available amenities included in your rental (electricity, sewage hookup, water, etc)"
        },
        {
            q: "What are Hookhub's fees?",
            a: "To keep our platform up and provide support- Hookhub collects 10% of your rental total and Stripe fees of 2.9%. Stripe"
        },
    ]

    return (
        <Flex
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            direction="column"
            width={width}
            marginBottom={35}
        >
            <Header textAlign="center" color={colors.amplifyNeutral90} level={mobileView ? 1.1 : 1.4} text={`Questions & Answers`}></Header>
            <InfoCard
                mobileView={mobileView}
                icon={<FontAwesome5 name="plug" size={24} color="#616A6B" style={{ marginRight: mobileView ? 0 : 10 }} />}
                title="Renter:"
                questions={renterQs}
                width={width}
            />
            <InfoCard
                mobileView={mobileView}
                icon={<AntDesign name="profile" size={24} color="#616A6B" style={{ marginRight: mobileView ? 0 : 10 }} />}
                title="Host:"
                questions={hostQs}
                width={width}
            />
            <Card
                backgroundColor={colors.orange2}
                border="#4c4e52"
                padding={!!mobileView ? 10 : 40}
                alignItems="center"
                textAlign="center"
                marginBottom={25}
                marginTop={55}
                width={!!mobileView ? width / 1.15 : width / 1.3}
            >
                <Image
                    style={{ width: 120, height: 31, marginBottom: 9 }}
                    source={require("../../assets/images/hookhub-logo_mini.png")}
                />
                <Text style={{ fontSize: 17, textAlign: "center" }}>
                    Hookhub is an online marketplace that connects people looking
                    to rent out their unused land space with people who are looking
                    for RV, Trailer or Boat parking and amenities.
                </Text>
            </Card>
        </Flex>
    );
}

export default LearnMore;
