import React, { useRef, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Pressable,
  Dimensions,
  ScrollView,
  useWindowDimensions
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Flex from "../CustomElements/Flex"
import { colors } from "../../styles/colors";
import { LoadingComponent } from "../LoadingComponent";
import { ErrorDisplay } from "../CustomElements/ErrorDisplay";
import { useSelector } from "react-redux";
import FooterView from "../other/FooterView";

function HostOnboarding({
  children,
  disabledNextButton = false,
  loading = false,
  heading,
  subtext = null,
  error = null,
  navigateF = null,
  navigateB,
  onClickNext = null,
  nextButtonText = "Next",
  showBackButton = true,
  multipleRequired = false,
  required = null
}) {
  const scrollRef = useRef();
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  const { mobileView } = useSelector(state => state.currentUser);

  const navigateForward = (e) => {
    e.preventDefault();
    !!onClickNext && onClickNext();
    !!navigateF && navigation.navigate(navigateF);
  };

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTo({
        y: 0,
        animated: true,
      })
    }
  }, []);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }} ref={scrollRef}>
      <View style={styles.container}>
        <View style={styles.headerSection}>
          <View style={styles.textBox}>
            <Text style={styles.text}>{heading}</Text>
          </View>
          {subtext && (
            <View style={styles.subtextBox}>
              <Text style={styles.subtext}>{subtext}</Text>
            </View>
          )}
        </View>
        {children}
        {error && <ErrorDisplay error={error} />}
        {nextButtonText === "Next" ? (
          <View style={styles.buttonSection}>
            <Pressable
              onPress={() => navigation.navigate(navigateB)}
              style={styles.backButton}
            >
              <Text style={styles.backButtonText}>back</Text>
            </Pressable>
            {disabledNextButton || error ? (
              <Pressable style={styles.disabledButton} disabled>
                <Text style={styles.disabledButtonText}>Next</Text>
              </Pressable>
            ) : (
              <Pressable
                onPress={(e) => navigateForward(e)}
                style={styles.forwardButton}
              >
                <Text style={styles.forwardButtonText}>Next</Text>
              </Pressable>
            )}
          </View>
        ) : (
          <Flex direction="column" marginTop={2}>
            {disabledNextButton || error ? (
              <Pressable style={styles.disabledOtherButton} disabled>
                {loading ? <LoadingComponent /> : <Text style={styles.disabledButtonText}>{nextButtonText}</Text>}
              </Pressable>
            ) : (
              <Pressable
                onPress={(e) => navigateForward(e)}
                style={styles.otherButton}
              >
                <Text style={styles.otherButtonText}>{nextButtonText}</Text>
              </Pressable>
            )}
            {showBackButton && (
              <Pressable
                onPress={() => navigation.navigate(navigateB)}
                style={styles.backButton}
              >
                <Text style={styles.backButtonText}>back</Text>
              </Pressable>
            )}
          </Flex>
        )}
      </View>
      <FooterView />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: Dimensions.get('window').width < 400 ? 25 : 50,
    paddingBottom: 100
  },
  headerSection: {
    marginBottom: 15,
    marginTop: Dimensions.get('window').width < 400 ? 10 : 25,
  },
  textBox: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 25,
  },
  subtextBox: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    color: colors.amplifyNeutral90,
    fontSize: Dimensions.get('window').width < 400 ? 31 : 33,
    fontWeight: "500",
    textAlign: "center",
  },
  subtext: {
    color: colors.amplifyNeutral90,
    fontSize: Dimensions.get('window').width < 400 ? 14 : 18,
    textAlign: "center",
  },
  image: {
    justifyContent: "center",
    alignItems: "center",
    height: 120,
    width: 160,
    boxShadow: "#737272",
  },
  buttonSection: {
    marginTop: 30,
    flexDirection: "row",
    justifyContent: "center",
  },
  backButtonText: {
    color: "#484a4a",
    fontSize: 20,
    fontWeight: "bold",
  },
  backButton: {
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 35,
  },
  forwardButtonText: {
    color: colors.amplifyNeutral80,
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "#616A6B",
    borderRadius: 7,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    marginLeft: 25,
  },
  otherButtonText: {
    color: "white",
    fontSize: 20
  },
  otherButton: {
    shadowColor: "#252625",
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    borderColor: colors.orange,
    borderRadius: 7,
    width: 180,
    borderWidth: 1,
    backgroundColor: colors.orange,
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
  },
  disabledButtonText: {
    color: "#99A3A4",
    fontSize: 20,
  },
  disabledButton: {
    borderColor: "#616A6B",
    borderRadius: 7,
    width: 100,
    borderWidth: 1,
    backgroundColor: "#616A6B",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    marginLeft: 25,
  },
  disabledOtherButton: {
    shadowColor: "#252625",
    shadowOffset: { width: -3, height: 5 },
    shadowOpacity: 0.5,
    shadowRadius: 3,
    borderColor: "#676767",
    borderRadius: 7,
    width: 180,
    borderWidth: 1,
    backgroundColor: "#676767",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
  },
});

export default HostOnboarding;
