import {
    Pressable,
    StyleSheet,
    Text,
    View,
} from "react-native";
import Badge from "../CustomElements/Badge";
import { globalStyles } from "../../styles/styles";

const FilterOptions = ({ stayType, setStayType, mobileView }) => {
    return (
        <View style={mobileView ? globalStyles.centerColumn : globalStyles.center}>
            <View style={[globalStyles.center, { flexWrap: "wrap", marginTop: 15, marginBottom: mobileView ? 0 : 10 }]}>
                <Pressable style={{ width: 84, height: 40 }} onPress={() => setStayType('vacation')}>
                    <Badge onPress={() => setStayType('vacation')} margin={0} padding={mobileView ? 1 : 2} variation={stayType == 'vacation' ? "warning" : "info"} height={23}>
                        <Text style={styles.fontSize}>Nightly</Text>
                    </Badge>
                </Pressable>
                <Pressable style={{ width: 84, height: 40 }} onPress={() => setStayType('monthly')}>
                    <Badge onPress={() => setStayType('monthly')} margin={0} padding={mobileView ? 1 : 2} variation={stayType == 'monthly' ? "warning" : "info"} height={23}>
                        <Text style={styles.fontSize}>Monthly</Text>
                    </Badge>
                </Pressable>
                <Pressable style={{ width: 84, height: 40 }} onPress={() => setStayType('type_yearly')}>
                    <Badge onPress={() => setStayType('type_yearly')} margin={0} padding={mobileView ? 1 : 2} variation={stayType == 'type_yearly' ? "warning" : "info"} height={23}>
                        <Text style={styles.fontSize}>Yearly</Text>
                    </Badge>
                </Pressable>
                <Pressable style={{ width: 84, height: 40 }} onPress={() => setStayType('storage')}>
                    <Badge onPress={() => setStayType('storage')} margin={0} padding={mobileView ? 1 : 2} variation={stayType == 'storage' ? "warning" : "info"} height={23}>
                        <Text style={styles.fontSize}>Storage</Text>
                    </Badge>
                </Pressable>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    image: {
        flex: 1,
        justifyContent: "center"
    },
    showmapbutton: {
        alignItems: "center",
        borderRadius: 10,
        backgroundColor: "#fff",
        borderColor: "#d6d6d6",
        borderRadius: 30,
        borderWidth: 1,
        borderBottomWidth: 2,
        justifyContent: "center",
        paddingHorizontal: 15,
    },
    filterButton: {
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: 30,
        elevation: 1,
        flexDirection: "row",
        height: 45,
        justifyContent: "center",
        marginHorizontal: 10,
        top: 15,
        width: 110,
        zIndex: 1,
    },
    filterButtonText: {
        marginLeft: 4,
        color: "#000",
        fontSize: 14,
        fontWeight: "bold",
    },
    fontSize: {
        fontSize: 15,
        paddingBottom: 2
    }
});

export default FilterOptions;