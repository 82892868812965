import React, { useState, useEffect, useRef } from "react";
import { useWindowDimensions, View, Animated, Text } from "react-native";
import { Ionicons } from '@expo/vector-icons';
import CalendarView from "./CalendarView";
import getEnvVars from "../../../environment";
import Header from "../CustomElements/Header";
import MapView from "./MapView";
import ReserveCard from "../booking/ReserveCard";
import SpaceDescription from "./SpaceDescription";
import ThingsToKnow from "./ThingsToKnowView";
import ThingsToDoInArea from "./ThingsToDoInAreaView";
import { useSelector } from "react-redux";
import { colors } from "../../styles/colors";
import { useNavigation } from "@react-navigation/native";
import Badge from "../CustomElements/Badge";
import MultipleSpacesDisplay from "./MultipleSpacesDisplay";
import { ReviewView } from "../review/ReviewView";
import { ShareModal } from "../other/ShareModal";
import { globalStyles } from "../../styles/styles";
import CustomDivider from "../CustomDivider";
import Card from "../CustomElements/Card";
import DetailedSpaceHeader from "./DetailedSpaceHeader";
import { addOrRemoveFavorite } from "../../utils/addOrRemoveFavorite";
const { REACT_APP_ENV } = getEnvVars();

function DetailedSpace({
  user = null,
  mySpace = null,
  property,
  images,
  imLoggedIn,
  activeSpaces,
  displaySpace,
  setDisplaySpace,
  initialDate,
  currentDateSpan,
  spaceTypes,
  hostUser,
  updateDates,
  calendarError,
  setCalendarError,
  scrollAndSetHeight,
  setSignUpModalOpen,
  signUpModalOpen
}) {
  const navigation = useNavigation();
  const { mobileView } = useSelector(state => state.currentUser);
  const { width } = useWindowDimensions();
  const translation = useRef(new Animated.Value(-100)).current;
  const { title, ownerID, address, id, reviews } = property;
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [showReserveCard, setShowReserveCard] = useState(true);
  const [favorited, setFavorited] = useState(false);
  const allReviews = reviews?.items;
  const numOfSpaces = activeSpaces.length;
  const { pets_allowed, smoking_allowed, outdoor_fires, check_in_time, quiet_hours_start, full_refund_date, half_refund_date, water_hookup, electric_hookup, sewage_hookup, adults_only } = displaySpace
  const anyRulesPresent = adults_only || pets_allowed || !smoking_allowed || outdoor_fires || check_in_time || quiet_hours_start || full_refund_date || half_refund_date;
  const amenitiesPresent = (
    water_hookup ||
    electric_hookup ||
    sewage_hookup
  );

  const checkForFavorite = async () => {
    const { favorites } = user.userData.userData;
    if (!!favorites && !!favorites.length) {
      const favoritesCheck = favorites.findIndex((x => x === property.id));
      favoritesCheck !== -1 && setFavorited(true)
    }
  };

  const updateFavorite = async ({ v }) => {
    setFavorited(v);
    addOrRemoveFavorite({ v, user, propertyID: id })
  };

  useEffect(() => {
    !!imLoggedIn && checkForFavorite();
  }, [imLoggedIn]);

  useEffect(() => {
    if (!activeSpaces && !activeSpaces[0]) {
      navigation.navigate("explore")
    }
    setDisplaySpace(activeSpaces[0])
  }, [activeSpaces]);

  useEffect(() => {
    Animated.timing(translation, {
      toValue: showReserveCard ? 0 : -100,
      duration: 250,
      useNativeDriver: true,
    }).start();
  }, [showReserveCard]);

  return (
    <View style={globalStyles.pageHorizontalMargins}>
      <DetailedSpaceHeader
        width={width}
        mobileView={mobileView}
        images={images}
        title={title}
        setShareModalOpen={() => setShareModalOpen(!shareModalOpen)}
        imLoggedIn={imLoggedIn}
        updateFavorite={(v) => imLoggedIn ? updateFavorite({ v }) : setSignUpModalOpen(true)}
        favorited={favorited}
      />
      <View style={[globalStyles.center, { alignItems: "flex-start" }]}>
        <View style={[globalStyles.flexStartColumn, { marginBottom: 20 }]}>
          <SpaceDescription
            hostUser={hostUser}
            userData={user?.userData}
            space={displaySpace}
            property={property}
            mobileView={mobileView}
            ownerID={ownerID}
            width={width}
            address={address}
            allReviews={allReviews}
            numOfSpaces={numOfSpaces}
            amenitiesPresent={amenitiesPresent}
            displaySpace={displaySpace}
          />
          {
            activeSpaces.length > 1 ? (
              <MultipleSpacesDisplay
                mobileView={mobileView}
                displaySpace={displaySpace}
                setDisplaySpace={(v) => setDisplaySpace(v)}
                spaces={activeSpaces}
                amenitiesPresent={amenitiesPresent}
                propertyImage={property.images[0]}
                width={width}
                electric_hookup={electric_hookup}
                water_hookup={water_hookup}
                sewage_hookup={sewage_hookup}
              />
            ) : (<></>)
          }
          {anyRulesPresent && <><ThingsToKnow space={displaySpace} compact={true} /><CustomDivider width={"90%"} /></>}
          {!!displaySpace?.things_to_do.length ? (
            <>
              <ThingsToDoInArea
                thingsToDo={displaySpace?.things_to_do}
                width={mobileView ? width / 1.2 : width / 2.2}
                mobileView={mobileView}
              />
              {!mobileView ? <CustomDivider width={"90%"} /> : <></>}
            </>
          ) : <></>}
        </View>
        {!mobileView && !mySpace &&
          <>
            <Animated.View
              style={{
                position: 'sticky',
                top: 0,
                left: 0,
                right: 0,
                transform: [
                  { translateX: translation },
                ],
                borderRadius: 12,
                borderColor: colors.brandGrey,
                borderWidth: .05,
                borderBottomWidth: 1.5,
                backgroundColor: "#fff",
                padding: 30,
                width: width / 3,
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                marginTop: 10,
                shadowColor: "grey",
                shadowOffset: {
                  width: 1,
                  height: 3,
                },
                shadowOpacity: 0.25,
                shadowRadius: 8,
              }}
            >
                <ReserveCard
                  mobileView={mobileView}
                  userData={user?.userData}
                  signUpModalOpen={signUpModalOpen}
                  setSignUpModalOpen={(v) => setSignUpModalOpen(v)}
                  mySpace={mySpace}
                  hostEmail={hostUser.email}
                  hostID={hostUser.id}
                  stripeConnectedAccountId={hostUser.stripeConnectedAccountId}
                  displaySpace={displaySpace}
                  setDisplaySpace={(v) => setDisplaySpace(v)}
                  displayProperty={property}
                  propertyID={id}
                  propertyTitle={title}
                  setSelectedDateSpan={(v) => updateDates(v)}
                  currentDateSpan={currentDateSpan}
                  spaceTypes={spaceTypes}
                  scrollToTop={() => scrollAndSetHeight()}
                  initialDate={initialDate}
                  error={calendarError}
                  activeSpaces={activeSpaces}
                  calendarDisplayForDateInput={true}
                />
            </Animated.View>
            <View style={globalStyles.flexStartColumn}></View>
          </>
        }
      </View>
      {!!allReviews?.length && <ReviewView reviews={allReviews} showFirstThree={true} />}
      <MapView space={property} width={width} />
      <View style={[{ elevation: 4, width: width, backgroundColor: colors.white, paddingBottom: 40, paddingTop: mobileView ? 10 : 40, marginTop: 35 }, globalStyles.centerColumn]}>
        <View style={mobileView ? globalStyles.flexStartColumn : globalStyles.flexStart}>
          {activeSpaces.length > 1 && (
            <View style={[globalStyles.flexStartColumn]}>
              <Header
                text="Spaces on this property:"
                color={colors.amplifyNeutral80}
                level={1}
                textAlign="left"
                marginBottom={mobileView ? 15 : 20}
              />
              <View>

                {(activeSpaces).map((v, i) => (
                  <Badge onPress={() => setDisplaySpace(v)} margin={1} variation={displaySpace.id === v.id ? "warning" : "info"} width={mobileView ? width / 1.3 : width / 3} alignContent="center" alignItems="center" justifyContent="flex-start" >
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                      <Ionicons name={displaySpace.id === v.id ? "radio-button-on-sharp" : "radio-button-off-sharp"} size={24} color={displaySpace.id === v.id ? colors.amplifyNeutral90 : colors.amplifyNeutral80} />
                      <Text
                        style={{ marginLeft: 5, fontSize: 17, width: "90%" }}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                      >
                        {v.title}
                      </Text>
                    </View>
                  </Badge>
                ))}
              </View>
            </View>
          )}
          <CalendarView
            mobileView={mobileView}
            space={displaySpace}
            setSelectedDateSpan={(v) => updateDates(v)}
            currentDateSpan={currentDateSpan}
            initialDate={initialDate}
            image={images[0]}
            width={width}
            mySpace={mySpace}
            error={calendarError}
            setError={(v) => setCalendarError(v)}
          />
        </View>
        {!mobileView && !mySpace && !!hostUser && !!Object.keys(hostUser).length && (
          <View style={{ width: mobileView ? width / 1.5 : width / 1.8 }}>
            <Card
              backgroundColor={"#fff"}
              padding={30}
              width={"100%"}
              alignItems="center"
              textAlign="center"
              justifyContent="center"
              marginTop={10}
            >
              <ReserveCard
                mobileView={mobileView}
                userData={user?.userData}
                mySpace={mySpace}
                signUpModalOpen={signUpModalOpen}
                setSignUpModalOpen={(v) => setSignUpModalOpen(v)}
                hostEmail={hostUser.email}
                hostID={hostUser.id}
                stripeConnectedAccountId={hostUser.stripeConnectedAccountId}
                displaySpace={displaySpace}
                setDisplaySpace={(v) => setDisplaySpace(v)}
                displayProperty={property}
                propertyID={id}
                propertyTitle={title}
                setSelectedDateSpan={(v) => updateDates(v)}
                currentDateSpan={currentDateSpan}
                spaceTypes={spaceTypes}
                scrollToTop={() => scrollAndSetHeight()}
                initialDate={initialDate}
                error={calendarError}
                activeSpaces={activeSpaces}
              />
            </Card>
          </View>
        )}
      </View>
      <View style={{ marginBottom: 50 }} />
      <ShareModal
        modalVisible={shareModalOpen}
        setModalVisible={() => setShareModalOpen(!shareModalOpen)}
        mobileView={mobileView}
        width={width}
        shareLink={__DEV__ ? `http://localhost:19006/space/${id}` : REACT_APP_ENV === "development" ? `https://staging.d18w937drvm0l.amplifyapp.com/space/${id}` : `www.hookhub.co/space/${id}`}
      />
    </View>
  );
}

export default DetailedSpace;
