import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { colors } from '../../styles/colors';
import { useSelector } from 'react-redux';

const CloseButtonIcon= ({ onPress, margin=13 }) => {

    return (
        <View style={[styles.flexEnd, {margin: margin}]}>
            <Pressable onPress={() => onPress()}>
                <AntDesign name="closecircleo" size={25} color={colors.amplifyNeutral80} />
            </Pressable>
        </View>
    );
};

export default CloseButtonIcon

const styles = StyleSheet.create({
    flexEnd: {
        justifyContent: "flex-end",
        textAlign: "right",
        alignContent: "flex-end",
        alignItems: "flex-end",
        alignSelf: "flex-end"
    },
});

