import React, { useContext, useState } from "react";
import { StyleSheet, useWindowDimensions, Pressable, Text } from "react-native";
import Flex from "../CustomElements/Flex";
import Card from "../CustomElements/Card";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import CustomTextInput from "../CustomElements/CustomTextInput";
import { LoadingComponent } from "../LoadingComponent";
import { UserDataContext } from "../../utils/UserContextProvider";
import { colors } from "../../styles/colors";
import { globalStyles } from "../../styles/styles";
import Header from "../CustomElements/Header";
import CustomButton from "../CustomElements/CustomButton";

export default function HelpForm({ authed }) {
  const navigation = useNavigation();
  const { mobileView } = useSelector(state => state.currentUser);
  const { width } = useWindowDimensions();
  const user = useContext(UserDataContext);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [helpEmailShow, setHelpEmailShow] = useState(false);

  const sendHelpEmail = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { sub, email, name } = user?.user?.userData;
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        userID: sub,
        userEmail: email,
        userName: name,
        emailSubject: subject,
        emailBody: body
      })
    };
    fetch('https://7ehxkuroembqxttntka4aumigq0kedch.lambda-url.us-west-2.on.aws/', requestOptions)
      .then(response => console.log("success sending email", response.json()))
      .then(data => {
        setLoading(false)
        setSuccess(true);
      });
  };

  const doneSendingEmail = () => {
    setSuccess(false)
    setBody('');
    setSubject('');
    navigation.navigate("home");
  }

  return (
    <Flex width={width} justifyContent="center" alignItems="center" direction="column" marginTop={30} marginBottom={40}>
      <Header marginTop={20} level={!!mobileView ? 1 : 2} text={`${helpEmailShow ? "Reach out to us" : "Still have questions?"}`}></Header>
      {!helpEmailShow ? (
        <CustomButton
          width={160}
          backgroundColor={colors.brandGrey}
          color="#fff"
          textAlign="center"
          marginTop={5}
          border={colors.amplifyNeutral90}
          onPress={() => setHelpEmailShow(!helpEmailShow)}
          marginBottom={12}
        >
          <Text fontSize={17}>reach out to us</Text>
        </CustomButton>
      ) : (
        <>
          {authed ? (
            <Card
              backgroundColor="#fff"
              width={width / 1.15}
              padding={8}
            >
              {success ? (
                <Flex
                  alignContent="center"
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  width={width < 400 ? width / 1.2 : width / 1.1}
                >
                  <Ionicons name="checkmark-circle" size={40} color="#78b2d6" />
                  <Text style={[globalStyles.center, { fontSize: mobileView ? 17 : 19, marginTop: 15, width: width < 400 ? width / 1.2 : width / 1.5 }]}>Email sent successfully! We will reach out soon.</Text>
                  <Pressable
                    onPress={(e) => doneSendingEmail()}
                    style={styles.forwardButton}
                  >
                    <Text style={styles.forwardButtonText}>Close</Text>
                  </Pressable>
                </Flex>
              ) : (
                <>
                  <CustomTextInput
                    label="subject"
                    errorMessage="There is an error"
                    value={subject}
                    marginBottom={9}
                    width={width / 1.2}
                    placeholder="subject"
                    onChangeText={(value) => setSubject(value)}
                  />
                  <CustomTextInput
                    label="description"
                    multiline={true}
                    errorMessage="There is an error"
                    size="medium"
                    marginBottom={1}
                    width={width / 1.2}
                    placeholder="I have a question..."
                    height={300}
                    onChangeText={(value) => setBody(value)}
                  />
                  <Flex
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={20}
                  >
                    {loading ? (
                      <LoadingComponent />
                    ) : (
                      <>
                        <Pressable
                          onPress={(e) => sendHelpEmail(e)}
                          style={!!subject.length && !!body.length ? styles.forwardButton : styles.disabledButton}
                        >
                          <Text style={!!subject.length && !!body.length ? styles.forwardButtonText : styles.disabledButtonText}>Send</Text>
                        </Pressable>
                      </>
                    )}
                  </Flex>
                </>
              )}
            </Card>
          ) : (
            <Flex
              alignItems="center"
              textAlign="center"
              justifyContent="center"
              marginBottom={12}
              wrap="wrap"
            >
              {!!authed ? (
                <HelpForm />
              ) : (
                <>
                  <Header level={1} text="Email Hookhub at: "></Header>
                  <Text style={{ textDecorationLine: "underline" }}>support@hookhub.co</Text>
                </>
              )}
            </Flex>
          )}
        </>
      )}
    </Flex>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  helpButtonText: {
    color: "#000",
    fontSize: 20,
  },
  helpButton: {
    borderColor: "grey",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: "grey",
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
  },
  forwardButtonText: {
    color: "#fff",
    fontSize: 20,
  },
  disabledButtonText: {
    color: "#676767",
    fontSize: 20,
  },
  forwardButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: colors.lightBlue,
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
    marginTop: 25
  },
  disabledButton: {
    borderColor: "#616A6B",
    borderRadius: 10,
    width: 100,
    borderWidth: 1,
    backgroundColor: colors.brandGrey,
    alignItems: "center",
    borderBottomWidth: 2,
    justifyContent: "center",
    alignSelf: "center",
    height: 40,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
